




import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ImageRound extends Vue {
  @Prop()
  imagesize?: string | number

  get setImageSize(): { [key: string]: string | number } {
    if (this.imagesize) {
      return {
        '--width': this.imagesize,
        '--height': this.imagesize,
      }
    }
    return {}
  }
}
