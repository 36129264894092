







import { Component, Mixins, Prop } from 'vue-property-decorator'
import LoginMethods from '@/components/organisms/LoginMethods.vue'

@Component
export default class LogoutMenu extends Mixins(LoginMethods) {
  @Prop()
  mode!: string

  @Prop()
  menutype?: string

  private showLogout = false

  private nameMouseover(): void {
    this.showLogout = true
  }

  private nameMouseleave(): void {
    this.showLogout = false
  }

  get classObject(): { [key: string]: boolean } {
    return {
      'logout-menu__dropdown__link__menu-default': !this.menutype,
      'logout-menu__dropdown__link__menu-teacher': this.menutype === 'teacher',
    }
  }
}
