
import { Component, Vue } from 'vue-property-decorator'

// mixinして使用する
// リアルタイム更新する場合、ここにイベントを設定するなどして集約したい
@Component
export default class NowDateTime extends Vue {
  private timestamp: Date = new Date()

  protected nowDateAsDisplay(): string {
    return `${this.timestamp.getFullYear()}年${this.timestamp.getMonth() + 1}月${this.timestamp.getDate()}日`
  }

  protected nowTimeAsDisplay(): string {
    const minutes = `00${this.timestamp.getMinutes()}`
    const hours = `00${this.timestamp.getHours()}`
    return `${hours.slice(-2)}:${minutes.slice(-2)}`
  }
}
