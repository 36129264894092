import { Component, Vue } from 'vue-property-decorator'
import Lesson from '@/mixins/action-cable/LessonAbstract'

@Component
export default class LessonStudentNotification extends Lesson {
  public watchIsConnected() {
    Vue.prototype.$logger.info(`-- LessonStudent watchIsConnected`)

    // すでにどこかのコンポーネントにて監視済みの場合はwatchの二重登録を行わない
    if (this.isConnectedWatched) return

    const unwatch = this.$watch('isConnected', (newValue: boolean, oldValue: boolean): void => {
      Vue.prototype.$logger.info(`-- LessonStudent isConnectedChange newValue:${newValue} oldValue:${oldValue}`)

      // 現状はないが確立解除の場合は何もしない
      if (!newValue) return

      // 現在ページのメッセージスタックを処理
      this.noticeCurrentPageAfterConnected()
    })
    // unwatch関数をwatch済み変数として登録
    this.$store.commit('lessonActionCable/setIsConnectedWatched', unwatch)
  }

  private async noticeCurrentPageAfterConnected() {
    Vue.prototype.$logger.info('-- noticeCurrentPageAfterConnected')

    // ページ遷移時にスタックに貯めた現在ページのメッセージスタックを取得
    const stackMessage = await this.$store.dispatch('stack/pop', 'currentPageStack')
    // ここではスタックは一つのみ処理して残りは破棄することとする
    this.$store.commit('stack/deleteAll', 'currentPageStack')

    // undefinedでなければメッセージスタックを元に現在ページをブロードキャストする
    if (stackMessage) {
      this.noticeCurrentPage({
        currentPage: stackMessage.currentPage || '',
        pageTitle: stackMessage.pageTitle || '',
        classModeCode: stackMessage.classModeCode || '',
        point: stackMessage.point || 0,
      })
    }
  }

  /**
   * 現在のページを伝える
   */
  protected async noticeCurrentPage(payload: {
    currentPage?: string
    pageTitle?: string
    classModeCode?: string
    point?: number
  }): Promise<void> {
    Vue.prototype.$logger.info(
      `-- LessonStudent noticeCurrentPage ${payload.currentPage}, ${payload.pageTitle}, ${payload.classModeCode}, ${payload.point}`
    )

    // ! 授業モード更新の場合はclassModeNameをそれ以外の場合はcurrentPageを入れる。追加時には要修正
    let messageType = 'currentPage'
    if (payload.classModeCode) {
      messageType = 'classModeName'
    } else if (payload.point) {
      messageType = `timePassed${payload.point * 2}`
    }

    // 何もページ情報がなければ空を返す
    // if (payload.currentPage === '') return

    // 現在ページを更新してAPI側からブロードキャストする
    try {
      await Vue.prototype.$http.httpWithToken.post(
        `/history/resultAttention/${this.$store.getters['lessonActionCable/lessonId']}/setCurrentPage`,
        {
          messageType: messageType,
          data: {
            reason: messageType,
            currentPage: payload.currentPage || '',
            pageTitle: payload.pageTitle || '',
            classModeCode: payload.classModeCode || '',
            point: payload.point || 0,
          },
        }
      )
    } catch {
      // TODO エラー処理
      alert('エラーが発生しました')
      throw 'Drill Error!'
    }
  }

  /**
   * ページ情報を通知する
   * WebSocketコネクション確立済みの場合は現在ページをブロードキャスト
   * WebSocketコネクションが確立されていない場合はスタックに追加
   *
   * @param payload ページ情報
   */
  protected noticeOrStackCurrentPage(payload: {
    currentPage?: string
    pageTitle?: string
    classModeCode?: string
    point?: number
  }) {
    Vue.prototype.$logger.info('-- noticeCurrentPageMethod')

    if (this.isConnected) {
      // WebSocketコネクション確立済みの場合は現在ページをブロードキャスト
      this.noticeCurrentPage(payload)
    } else {
      // WebSocketコネクションが確立されていない場合はスタックに追加
      // 最終的にコネクション確立後最後のスタックのみブロードキャストして他は破棄する
      this.$store.commit('stack/push', {
        stateName: 'currentPageStack',
        item: {
          currentPage: payload.currentPage || '',
          pageTitle: payload.pageTitle || '',
          classModeCode: payload.classModeCode || '',
          point: payload.point || 0,
        },
      })
    }
  }

  /**
   * 指定時間経過後に通知を行う
   *
   * @param time 指定の時間経過後に実行。単位は分。
   * @param payload ページ情報
   * @return timeoutId キャンセル用のsetTimeoutのID
   */
  protected noticeOrStackCurrentPageDelayed(
    time: number,
    payload: {
      currentPage?: string
      pageTitle?: string
      classModeCode?: string
      point?: number
    }
  ) {
    Vue.prototype.$logger.info('-- noticeOrStackCurrentPageDelayed')

    const timeoutId = setTimeout(() => {
      Vue.prototype.$logger.log(`noticeOrStackCurrentPageDelayed processed ${time}`)
      this.noticeOrStackCurrentPage(payload)
    }, time * 1000 * 60)

    this.$store.commit('queue/enqueue', {
      stateName: 'setTimeoutIdQueue',
      item: timeoutId,
    })
  }
}
