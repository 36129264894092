export enum ChallengeProcess {
  FULL='completed',
  HALF = 'incompleted',
  ZERO = 'not_drill',
}

export enum ConditionTypeBadge {
  StudyQuestions='studied_questions',
  StudyDays='studied_days',
  StudyConsecutiveDays ='studied_consecutive_days',
  Other = 'others'
}

export enum LevelUnits {
  LARGE = 'l',
  MEDIUM = 'm',
  SMALL = 's',
}

export interface Unit {
  id: number
  name: string
  status: ChallengeProcess
  level: LevelUnits
}

export enum TypeClickEntrace {
  Random = 'random',
  Bookmark = 'bookmark',
  Unit = 'unit',
}

export interface CurriculumType {
  title: string;
  scopeLevel: string;
  drillType: string;
  scopeId: number;
  correctCount: number;
  incorrectCount: number;
  notDrillCount: number;
  isLProblem: number;
  subjectCode: number;
  problems: ProblemType[];
}

export interface ResultUnitType {
  badges: Badge[];
  correct_count: number;
  incorrect_count: number;
  not_drill_count: number;
  rank: any;
}

export interface Badge {
  badge_category_id: number;
  condition_type?: string;
  condition_value?: number;
  id?: number;
  name: string;
  icon_path?: string;
  created_at?: string;
  updated_at?: string;
  description: string;
  is_attended: boolean;
}

export interface ProblemType {
  sProblemId: number;
  sProblemCode: string;
  number: number;
  images: string[];
  audios: any;
  isBookmarked: boolean;
  existMovie: boolean;
  questions: QuestionType[]
}

export interface QuestionType {
  questionCode: string;
  contents: AnswerType[];
  questionTextContent: string | null;
  questionTextExplain: string | null;
}

export interface AnswerType {
  labelText: string;
  choiceText: string;
  isCorrectText: boolean;
  sortNum: number;
  answerFormatCode: string;
  disabled?: boolean;
}

export interface QuestionStoreType {
  images: string[];
  audios: any;
  isBookmarked: boolean;
  answers: AnswerType[];
  questionTextContent: string | null;
  questionTextExplain: string | null;
  questionCode: string;
  sProblemId: number;
  sProblemCode: string;
  answerFormatCode: QuestionCode
}
export interface AnswerStoreType extends QuestionStoreType {
  yourChoose: AnswerType
}

export interface AnswersRequestType {
  s_problem_id: number;
  questions: {
    question_code: string;
    answer: string[];
    answer_format_code: string;
    correct: boolean;
  } []
}

export enum QuestionCode {
  SINGLE_CHOICE = 'SINGLE_CHOICE',
  FLASHCARD = 'FLASHCARD',
}
