import _Vue from 'vue'

export default {
  install(Vue: typeof _Vue): void {
    const containerId = 'quiz-container-result'
    const complete = function (): void {
      const container = document.getElementById(containerId)
      if (container) {
        container.remove()
      }
    }
    const createImage = function (parentId: string, isCorrect = false) {
      const container = document.createElement('div')
      container.id = containerId
      const shape = document.createElement('div')
      const overlay = document.createElement('div')
      overlay.id = 'quiz-overlay'
      overlay.classList.add(isCorrect ? 'correct' : 'incorrect')
      shape.id = isCorrect ? 'shape-correct' : 'shape-incorrect'
      shape.classList.add('shape')
      container.appendChild(overlay)
      container.appendChild(shape)
      const parent = document.getElementById(parentId)
      parent?.setAttribute('style', 'position: relative;')
      parent?.appendChild(container)
    }

    const correct = function (parentId: string, time = 1000) {
      createImage(parentId, true)
      return new Promise<void>((resolve, reject) => {
        setTimeout(() => {
          complete()
          resolve()
        }, time)
      })
    }
    const incorrect = function (parentId: string, time = 1000) {
      createImage(parentId)
      return new Promise<void>((resolve, reject) => {
        setTimeout(() => {
          complete()
          resolve()
        }, time)
      })
    }

    Vue.prototype.$check = { correct, incorrect }
  },
}
