












import { Component, Mixins } from 'vue-property-decorator'
import FillRound from '@/components/atoms/FillRound.vue'
import ImageRound from '@/components/atoms/ImageRound.vue'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import Sidebar from '@/components/molecules/quiz/Menu.vue'
import TooltipQuiz from '@/mixins/quiz/Tooltip'
import BaseQuiz from '@/mixins/quiz/BaseQuiz'
import ModalConfirmLeave from '@/mixins/quiz/ModalConfirmLeave'

@Component({
  components: {
    FillRound,
    ImageRound,
    ColoredBox,
    ButtonBase,
    Sidebar,
    ModalConfirmLeave,
  },
})
export default class QuizHeader extends Mixins(TooltipQuiz, BaseQuiz, ModalConfirmLeave) {
  private callBack: any

  private created() {
    this.title = this.nameBook() as string
  }

  private guardTransition(nextStep: any) {
    const pathName = this.$route.path
    if (typeof nextStep === 'function') {
      this.callBack = nextStep
    } else {
      this.callBack = () => this.$router.push(nextStep)
    }
    if (pathName === '/quiz/question') {
      return this.modalLeave.show()
    }
    this.callBack()
  }

  private goToAchievement() {
    return this.push('/quiz/achievements', 'この書籍の実績')
  }

  private onConfirm() {
    this.callBack()
  }
}
