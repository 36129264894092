




























import { Component, Mixins, Vue } from 'vue-property-decorator'
import ModalBase from '@/components/molecules/v3/ModalBase.vue'
import ModalBaseMethod from '@/components/molecules/ModalBaseMethod.vue'
import FaceRadio from '@/components/molecules/v3/FaceRadio.vue'
import moment from 'moment'
import SelectWide from '@/components/molecules/SelectWide.vue'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import { PREFIX_URL_NOT_PAGE_STUDY, STATUS_NOTE_REPORT, TYPE_NOTE_REPORT, PAGE_STUDY } from '@/constants'

@Component({
  components: {
    ModalBase,
    FaceRadio,
    SelectWide,
    ColoredBox,
    ButtonBase,
  },
})
export default class ModalNoteReport extends Mixins(ModalBaseMethod) {
  private lessonId = ''
  public classMode(): string {
    return Vue.prototype.$cookies.get('dataGdls').classMode || 'TJ'
  }
  private title = 'メモ・レポート'
  private titleResult = '学習ページ以外'
  private date = moment().locale('ja').format('YYYY年MM月DD日')
  private className = ''
  private lookBackEnableOptions: { text: string; value: string }[] = []
  private userId = Vue.prototype.$cookies.get('authGdls').currentApiUserId
  private reportResult = STATUS_NOTE_REPORT.wellDone
  private currentUrl = ''
  private note_report_canvas = ''
  private comment = ''
  private get commentError(): string {
    return this.comment.length > 300 ? '300文字以内で入力してください' : ''
  }
  private selectedLessonId = this.lessonId

  private isLoading = false

  private get currentName() {
    return this.$route.name as string
  }
  public showModal() {
    Vue.prototype.$loading.start()
    if (this.isPageStudy) {
      this.titleResult = this.$store.getters['drillsV3/titleResult'] || 'エラー'
    } else {
      this.titleResult = '学習ページ以外'
    }
    this.lessonId = this.$store.getters['lessonActionCable/lessonId']
      ? this.$store.getters['lessonActionCable/lessonId']
      : null
    this.currentUrl = window.location.href
    Vue.prototype.$loading.complete()
    this.show()
  }

  private get isPageStudy() {
    return PAGE_STUDY.includes(this.currentName)
  }

  private async cancel() {
    this.comment = ''
    this.reportResult = STATUS_NOTE_REPORT.wellDone
    this.hide()
  }

  private get colortype(): string {
    return this.comment.length >= 0 && !this.commentError ? 'skyblue' : 'pointer-events-none'
  }

  private getPathName() {
    const index = this.currentUrl.indexOf('/student/')
    let url = this.currentUrl.substring(index + 8)
    if (['drillV3', 'drillScoringV3', 'drillV2', 'drillScoringV2'].includes(this.currentName)) {
      const version = ['drillV3', 'drillScoringV3'].includes(this.currentName) ? 'V3' : 'V2'
      const sProblemIds: any[] = this.$store.getters[`drills${version}/allProblems`][0]?.sProblemIds || []
      url = url + `&sProblemIds=[${sProblemIds?.join(',')}]`
    }
    if (!this.isPageStudy) {
      url = PREFIX_URL_NOT_PAGE_STUDY + url
    } else {
      const version = this.currentName === 'drillV3' ? 'V3' : 'V2'
      if (!url.includes('subjectCode=')) {
        url += `&subjectCode=${
          this.$store.getters[`drills${version}/subjectCode`] ||
          this.$route.query?.subjectCode ||
          Vue.prototype.$cookies.get('dataGdls').subjectCode
        }`
      }
      if (!url.includes('classMode=')) {
        url += `&classMode=${this.classMode()}`
      }
    }
    if (['ExplanatoryVideo', 'ExplanatoryVideoV2', 'ExplanatoryVideoV3'].includes(this.currentName)) {
      url += '&isReportExplanatoryVideo=true'
    }
    return url
  }

  private async sendReport() {
    if (!this.isLoading) {
      this.isLoading = true
      let report_type = TYPE_NOTE_REPORT.status
      if (Number(this.reportResult) == STATUS_NOTE_REPORT.bug) {
        report_type = TYPE_NOTE_REPORT.bug
      } else if (Number(this.reportResult) == STATUS_NOTE_REPORT.question) {
        report_type = TYPE_NOTE_REPORT.question
      } else {
        report_type = TYPE_NOTE_REPORT.status
      }

      const apiPath = `/v3/user_note_reports`
      await Vue.prototype.$http.httpWithToken
        .post(apiPath, {
          note_report_type: report_type.toString(),
          note_report_title: this.titleResult,
          note_report_result: this.reportResult.toString(),
          note_report_comment: this.comment,
          note_report_url: this.getPathName(),
          lesson_id: this.lessonId,
        })
        .then(() => {
          this.comment = ''
          this.reportResult = 1
          this.hide()
        })
        .catch(() => {
          alert('更新に失敗しました。')
        })
        .finally(() => {
          this.hide()
          this.isLoading = false
        })
    }
  }
}
