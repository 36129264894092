import { Design } from '@/constants/design'
import { DesignName } from '@/types/student/design'
import _Vue from 'vue'

export default {
  install(Vue: typeof _Vue): void {
    const getNameDesign = () => {
      const dataGdls = Vue.prototype.$cookies.get('dataGdls')
      let nameDesign: number | string = dataGdls?.academyId
      if (dataGdls?.academyCode) {
        nameDesign = (dataGdls?.academyCode as string)?.toLowerCase()
      }
      switch (nameDesign) {
        case 98:
          return DesignName.Default
        case 100:
          return DesignName.Custom
        case DesignName.OnAir:
          return DesignName.OnAir
        case DesignName.YGC:
          return DesignName.YGC
        default:
          return DesignName.Default
      }
    }
    const properties = () => {
      const nameDesign = getNameDesign()
      const designObject = new Design(nameDesign)
      return designObject.properties()
    }
    Vue.prototype.$design = { properties }
  },
}
