
import { Component, Vue } from 'vue-property-decorator'

// mixinして使用する
// FIXME webpackに専用のローダーを設定する
@Component
export default class BootstrapLoader extends Vue {
  protected loadBootstrap(): void {
    if (!document.querySelector('style#css-load-bootstrap')) {
      const bootstrap = require('!!css-loader!!postcss-loader?modules!bootstrap/dist/css/bootstrap.css')
      const stylesBS = document.createElement('style')
      stylesBS.type = 'text/css'
      stylesBS.innerHTML = bootstrap.toString()
      stylesBS.setAttribute('id', 'css-load-bootstrap')
      document.head.appendChild(stylesBS)
    }
    if (!document.querySelector('style#css-load-bootstrap-vue')) {
      const bootstrapVue = require('!!css-loader!!postcss-loader?modules!bootstrap-vue/dist/bootstrap-vue.css')
      const stylesBSV = document.createElement('style')
      stylesBSV.type = 'text/css'
      stylesBSV.innerHTML = bootstrapVue.toString()
      stylesBSV.setAttribute('id', 'css-load-bootstrap-vue')
      document.head.appendChild(stylesBSV)
    }
  }
  protected unloadBootstrap(): void {
    const styleBS = document.querySelector('style#css-load-bootstrap')
    if (styleBS) {
      document.head.removeChild(styleBS)
    }
    const styleBSV = document.querySelector('style#css-load-bootstrap-vue')
    if (styleBSV) {
      document.head.removeChild(styleBSV)
    }
  }
}
