
const state = {
  status: {
    isOpen: false,
    message: ''
  }
}

const getters = {
  status: (state: any) => state.status,
}

const mutations = {
  open(state: any, message: string) {
    state.status = {
      isOpen: true,
      message
    }
  },

  close(state: any) {
    state.status = {
      isOpen: false,
      message: ''
    }
  },
}

const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
