import { Component, Vue } from 'vue-property-decorator'
import liff from '@line/liff'
@Component
export default class BaseQuiz extends Vue {
  protected get preTitle() {
    return this?.$route?.query?.preTitle || ''
  }

  protected async initLiff() {
    await liff.init({
      liffId: process.env.VUE_APP_LIFF_ID as string,
      withLoginOnExternalBrowser: true,
    })
    return liff
  }

  protected async saveProfileLine() {
    const profile = await liff.getProfile()
    localStorage.setItem('_prl', JSON.stringify(profile))
  }

  protected async getProfileLine() {
    try {
      const profile = localStorage.getItem('_prl')
      if (profile) {
        return JSON.parse(profile)
      }
      const liff = await this.initLiff()
      const profileAsync = await liff.getProfile()
      if (profileAsync) {
        return profileAsync
      }
      return {} as any
    } catch {
      return {} as any
    }
  }

  protected goTopPage() {
    const book = this.getBookInfo()
    this.$router.replace(`/quiz/units/${book.level}/${book.first_parent_id}`)
  }

  protected push(path: string, title: string, option?: { query?: any; method?: string }) {
    const route: any = {}
    route.path = path
    route.query = { ...option?.query, preTitle: title }
    const method = option?.method || 'push'
    this.$router[method](route)
  }

  protected saveBookInfo(book: any) {
    localStorage.setItem('book', JSON.stringify(book))
  }

  protected getBookInfo(): { code: string; first_parent_id: number; id: number; level: string; name: string } {
    try {
      const _book = localStorage.getItem('book')
      if (_book) {
        return JSON.parse(_book)
      }
      return {} as any
    } catch {
      return {} as any
    }
  }

  protected nameBook() {
    const book = this.getBookInfo()
    if (!book?.code) {
      this.$router.push('/quiz/error')
    } else {
      return book.name
    }
  }

  protected backOut() {
    const back = document.createElement('a')
    back.setAttribute('href', `https://line.me/R/oaMessage/${process.env.VUE_APP_LINE_ID}`)
    back.setAttribute('target', '_blank')
    back.click()
    back.remove()
  }
}
