






import { Component, Vue, Mixins } from 'vue-property-decorator'

import StudentHeader from '@/components/organisms/v3/StudentHeader.vue'
import PropertiesDesign from '@/mixins/v3/PropertiesDesign'

@Component({
  components: {
    StudentHeader,
  },
})
export default class StudentLayout extends Mixins(PropertiesDesign) {
  private toggleBodyClass(addRemoveClass: string, className: string): void {
    const el = document.body
    if (addRemoveClass === 'addClass') {
      el.classList.add(className)
    } else {
      el.classList.remove(className)
      el.setAttribute('style', '')
    }
  }
  //check page login
  get showHeader() {
    const pathName = this.$route.fullPath
    const isPageLogin = pathName.includes('login') || pathName === '/'
    const isV3 = Vue.prototype.$gdlsCookiesV3.isV3()
    if (!isPageLogin) {
      const el = document.body
      el.setAttribute('style', `--background-color: ${this.backgroundColor}`)
    }
    return !isPageLogin && isV3
  }
  get isV3() {
    return Vue.prototype.$gdlsCookiesV3.isV3()
  }

  private mounted(): void {
    this.toggleBodyClass('addClass', 'student')
  }

  private destroyed(): void {
    this.toggleBodyClass('removeClass', 'student')
  }
}
