





import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class FillRound extends Vue {
  @Prop()
  roundsize?: string | number

  @Prop()
  colortype?: string

  get setRoundSize(): {} {
    if (this.roundsize) {
      return {
        '--width': this.roundsize,
        '--height': this.roundsize,
      }
    }
    return {}
  }

  get classObject(): { [key: string]: boolean } {
    return {
      'fill-round__color-default': !this.colortype,
      'fill-round__color-gradation-orange': this.colortype === 'gradation-orange',
    }
  }
}
