
















import { Component, Mixins, Vue } from 'vue-property-decorator'
import FillRound from '@/components/atoms/FillRound.vue'
import ImageRound from '@/components/atoms/ImageRound.vue'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import DropdownMenu from '@/components/molecules/DropdownMenu.vue'
import NowDateTime from '@/components/atoms/NowDateTime.vue'
import LoginMethods from '@/components/organisms/LoginMethods.vue'
import LogoutMenu from '@/components/organisms/LogoutMenu.vue'

@Component({
  components: {
    FillRound,
    ImageRound,
    ColoredBox,
    ButtonBase,
    DropdownMenu,
    LogoutMenu,
  },
})
export default class AdminHeader extends Mixins(NowDateTime, LoginMethods) {
  private service = Vue.prototype.$cookies.get('dataGdls').service

  private dropdownMenuDatas: object[] =
    this.service === 'quiz'
      ? [{ url: '/admin/notification', name: 'お知らせ管理' }]
      : [
          { url: '/admin/branch', name: '教室管理' },
          { url: '/admin/customer', name: '塾管理' },
          { url: '/admin/request', name: '申請管理' },
          { url: '/admin/notification', name: 'お知らせ管理' },
          { url: '/admin/access-log', name: 'アクセスログ' },
        ]

  private async mounted() {
    // ログアウト用のモードを設定
    this.mode = this.MODE.ADMIN
  }
}
