













import { Component, Mixins, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import ButtonBase from '@/components/atoms/ButtonBase.vue' 
import TooltipQuiz from '@/mixins/quiz/Tooltip'
import ModalConfirmLeave from '@/mixins/quiz/ModalConfirmLeave'


@Component({
  inheritAttrs: false,
  components: {
    ButtonBase,
  }
})
export default class ButtonBack extends Mixins(TooltipQuiz, ModalConfirmLeave) {
  @Prop({default: false})
  typeConfirm?: boolean

  private goBack(){
    this.$router.back()
  }

  private showModal() {
    this.modalLeave.show();
  }
}
