import { KEY_ANSWERS, KEY_RESULT_RESPONSE } from '@/constants'
import { AnswerStoreType, AnswersRequestType, CurriculumType, TypeClickEntrace, Unit } from '@/types/quiz/units'
import { Component, Vue } from 'vue-property-decorator'
@Component
export default class UnitQuiz extends Vue {
  protected readonly levelCurriculum = {
    b: 'OriginalCurriculum',
    l: 'CurriculumLUnit',
    m: 'CurriculumMUnit',
    s: 'CurriculumSUnit',
  }
  protected readonly parentLevel = {
    l: 'b',
    m: 'l',
    s: 'm',
  }

  private awaitCallBookMark: any = {}

  private async toggleBookmark(params: { sProblemId: number; isBookmarked: boolean }) {
    const sProblemId = params.sProblemId
    if (this.awaitCallBookMark?.[sProblemId]) {
      return
    }
    this.awaitCallBookMark[sProblemId] = true
    const result = params.isBookmarked
      ? await Vue.prototype.$http.httpWithToken.delete(`/v3/history_quiz/quiz_bookmarks/${sProblemId}`)
      : await Vue.prototype.$http.httpWithToken.post('/v3/history_quiz/quiz_bookmarks', { s_problem_id: sProblemId })
    this.awaitCallBookMark[sProblemId] = false
    if (result?.status === 201 || result?.status === 204) {
      this.toggleMark(sProblemId)
    }
  }

  protected toggleMark(id: number) {
    const curriculum: CurriculumType = this.getCurriculum()
    const answers: AnswerStoreType[] = this.getAnswerLocalStorage()
    if (curriculum?.problems) {
      curriculum.problems.find((item) => {
        if (item.sProblemId === id) {
          item.isBookmarked = !item.isBookmarked
        }
      })
      this.$store.dispatch('question/setCurriculum', curriculum)
      this.saveCurriculum(curriculum)
    }
    if (answers?.length) {
      answers.find((item) => {
        if (item.sProblemId === id) {
          item.isBookmarked = !item.isBookmarked
        }
      })
      this.$store.dispatch('question/setAnswers', answers)
      this.saveAnswers(answers)
    }
  }

  protected saveCurriculum(curriculum: any) {
    localStorage.setItem('curriculum', JSON.stringify(curriculum))
  }

  protected getCurriculum() {
    try {
      const curriculum = localStorage.getItem('curriculum')
      if (curriculum) {
        return JSON.parse(curriculum)
      }
      return null
    } catch {
      return null
    }
  }

  protected removeCurriculum() {
    localStorage.removeItem('curriculum')
  }

  protected saveAnswers(answers: any) {
    localStorage.setItem(KEY_ANSWERS, JSON.stringify(answers))
  }

  protected removeAwswers() {
    localStorage.removeItem(KEY_ANSWERS)
  }

  protected getAnswerLocalStorage() {
    try {
      const answers = localStorage.getItem(KEY_ANSWERS)
      if (answers) {
        return JSON.parse(answers)
      }
      return []
    } catch {
      return []
    }
  }

  protected saveResult(result: any) {
    localStorage.setItem(KEY_RESULT_RESPONSE, JSON.stringify(result))
  }

  protected removeResult() {
    localStorage.removeItem(KEY_RESULT_RESPONSE)
  }

  protected getResultLocalStorage() {
    try {
      const result = localStorage.getItem(KEY_RESULT_RESPONSE)
      if (result) {
        return JSON.parse(result)
      }
      return null
    } catch {
      return null
    }
  }

  protected clearStorage() {
    this.removeAwswers()
    this.removeResult()
    this.removeCurriculum()
    this.$store.commit('question/setSubmitSuccess', 'pendding')
    this.$store.dispatch('question/setCurriculum', null)
    this.$store.dispatch('question/setAnswers', [])
    this.$store.dispatch('question/setResult', null)
  }

  protected async getUnits(level = 'b', id?: string | number) {
    const result = await Vue.prototype.$http.httpWithToken.get('/v3/curriculumSUnits', {
      params: { study_logic_type: 'QZ', unit_type: this.levelCurriculum[level], unit_id: id },
    })
    return (result?.data || []).map((item: Unit) => ({ ...item, level })) || []
  }

  protected async getQuestions(params: { scopeLevel: string; drill_type: string; scope_id: string | number }) {
    const result = await Vue.prototype.$http.httpWithToken.get('/v2/problemsForDragon', {
      params: {
        study_logic_type: 'QZ',
        drill_type: params.drill_type,
        scope_id: params.scope_id,
        scope_type: this.levelCurriculum[params.scopeLevel],
      },
    })
    return result?.data || null
  }

  protected async sendAnswers(params: {
    drill_type: string
    scope_type: string
    scope_id: number
    input: AnswersRequestType[]
  }) {
    const result = await Vue.prototype.$http.httpWithToken.patch(
      '/v3/resultProblems/unit',
      { ...params, study_logic_type: 'QZ' },
      {
        selfHandle: true,
      }
    )
    return result?.data || null
  }

  private async checkCountBookMark() {
    const result = await Vue.prototype.$http.httpWithToken.get('/v3/history_quiz/bookmark_count')
    return result.data?.count || 0
  }

  protected async startLearning(params: { scopeLevel: string; drill_type: string; scope_id: number }, title: string) {
    if (params.drill_type === TypeClickEntrace.Bookmark) {
      const countBookmark = await this.checkCountBookMark()
      if (!countBookmark) {
        this.$store.commit(
          'modal/open',
          'ブックマークされた問題がありません。<br/>クイズの結果表示画面から、問題をブックマークに追加できます。'
        )
        return Promise.reject('error')
      }
    }
    Vue.prototype.$count.start()
    const data = await this.getQuestions(params)
    try {
      if (data) {
        this.removeAwswers()
        data.title = title
        data.scopeLevel = params.scopeLevel
        data.drillType = params.drill_type
        data.scopeId = params.scope_id
        this.saveCurriculum(data)
        return Promise.resolve(data)
      }
    } catch (e) {
      return Promise.reject('error')
    }
    Vue.prototype.$count.complete()
    return Promise.reject('error')
  }
}
