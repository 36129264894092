import { Component, Vue, Ref } from 'vue-property-decorator'
import ModalLeaveQuestion from '@/components/organisms/quiz/ModalLeaveQuestion.vue'

@Component({
  components: {
    ModalLeaveQuestion
  }
})
export default class ModalConfirmLeave extends Vue {
  @Ref() 
  protected modalLeave!: ModalLeaveQuestion
}
