import { Component, Vue } from 'vue-property-decorator'

@Component
export default class LessonAbstract extends Vue {
  public async AbstractBeforeMountBefore() {
    Vue.prototype.$logger.info('-- LessonAbstract AbstractBeforeMountBefore')
  }

  public async AbstractBeforeMountAfter() {
    Vue.prototype.$logger.info('-- LessonAbstract AbstractBeforeMountAfter')
    await this.createSubscription()
  }

  protected get messages() {
    return this.$store.getters['lessonActionCable/messages']
  }

  /**
   * WebSocket通信が確立されたかどうか
   * Watch等で監視し確立したタイミングで何かしらの処理を実行するのに利用する
   */
  protected get isConnected() {
    return this.$store.getters['lessonActionCable/isConnected']
  }

  /**
   * WebSocket通信が確立されたかどうかを監視したかどうか
   */
  protected get isConnectedWatched() {
    return this.$store.getters['lessonActionCable/isConnectedWatched']
  }

  protected get messageChannel() {
    return this.$store.getters['lessonActionCable/messageChannel']
  }

  /**
   * ActionCableを利用してWebSocketによる通信を開始する
   */
  protected async createSubscription(): Promise<any> {
    Vue.prototype.$logger.info('-- LessonAbstract createSubscription')
    // ActionCable初期化
    await this.$store.commit('lessonActionCable/initCable')
    // 購読開始
    await this.$store.dispatch('lessonActionCable/subscriptions')
    Vue.prototype.$logger.info('-- LessonAbstract createSubscription end')
  }

  /**
   * サポーターを呼ぶAPIを叩く
   * API側では呼ばれるとその生徒が属している授業に属する先生たちにWebSocketにてブロードキャストする（予定）
   */
  protected async callSupporter(isEnable: boolean): Promise<void> {
    Vue.prototype.$logger.info(`-- LessonAbstract callSupporter`)

    // DB保存してサーバーからブロードキャストが正解かもしれないが、ひとまず生徒から文字列を送信
    this.$store.dispatch('lessonActionCable/perform', {
      messageType: 'supporterCall',
      data: {
        reason: 'supporterCall',
        isEnable: isEnable,
      },
    })
  }
}
