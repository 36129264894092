import { Component, Mixins, Vue } from 'vue-property-decorator'
import LessonStudentNotification from '@/mixins/action-cable/LessonStudent/notification'

@Component
export default class LessonStudent extends Mixins(LessonStudentNotification) {
  public async beforeMount() {
    await this.AbstractBeforeMountBefore()
    this.watchIsConnected()

    Vue.prototype.$logger.info('-- LessonStudent mounted')
    // すでに授業中かどうか判定ずみの場合は何もしない
    if (this.checkedInLesson !== null) return
    if (!Vue.prototype.$gdlsCookiesV3.isV3()) {
      const checkedInLesson = await this.inLesson()
      this.$store.commit('lessonActionCable/setCheckedInLesson', checkedInLesson)
    }
    if (!this.checkedInLesson) return

    await this.AbstractBeforeMountAfter()
  }

  /**
   * ActionCableを利用してWebSocketによる通信を開始する
   *
   * @return 授業中かどうか返す
   *         授業中である場合はWebSocket通信を開始
   */
  private async inLesson(): Promise<boolean> {
    // 授業IDをセット
    let result
    try {
      // 宿題、確認テスト取得API
      result = await Vue.prototype.$http.httpWithToken.get(`/lessons/inLesson`)
      Vue.prototype.$logger.info(result)

      this.$store.commit('lessonActionCable/setLessonId', result.data.lessonId || null)
      this.$store.commit('lessonActionCable/setJustFinishedLessonId', result.data.justFinishedLessonId || null)
    } catch (e) {
      // TODO エラー処理
      Vue.prototype.$logger.error(`授業取得でエラーが発生しました。 ${e}`)
      throw e
    }

    // 授業中である場合はtrueを返す
    return result && !!result.data.lessonId
  }

  /**
   * 授業中かどうか判定済みかどうか
   */
  protected get checkedInLesson() {
    return this.$store.getters['lessonActionCable/checkedInLesson']
  }

  /**
   * 授業中の授業ID
   */
  protected get lessonId() {
    return this.$store.getters['lessonActionCable/lessonId']
  }

  /**
   * 授業終了30分以内の直近の授業ID
   */
  protected get justFinishedLessonId() {
    return this.$store.getters['lessonActionCable/justFinishedLessonId']
  }
}
