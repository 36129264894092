const state = {
  playingAudio: false,
}

const getters = {
  playingAudio: (state: any) => state.playingAudio,
}

const mutations = {
  setPlayingAudio(state: any, payload: boolean) {
    state.playingAudio = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
}
