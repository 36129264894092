import { Vue } from 'vue-property-decorator'
import {
  ImagePaths,
  ResponseProblem,
  ResultProblem,
  ResponseResultProblem,
  ResponseQuestion,
} from '@/mixins/drillsv2/WebApi'
import { ResponseResultDrill } from '@/mixins/drillsv2/HistoryApi'

/**
 * APIレスポンスの問題情報をストア保持用に変換
 *
 * @param responseProblem APIレスポンスの問題情報
 * @returns ストア保持用の問題情報
 */
function parseResponseToProblem(responseProblem: ResponseProblem): Problem {
  // 問題IDを配列化
  const sProblemIds = responseProblem.problems.map((problem) => {
    return problem.sProblemId
  })
  // 問題コードを配列化
  const sProblemCodes = responseProblem.problems.map((problem) => {
    return problem.sProblemCode
  })

  // 問題ごとの設問数を配列化
  const questionNumber: number[] = responseProblem.problems.map((problem) => {
    return problem.number
  })

  // 設問数をカウント
  const totalNumber = questionNumber.reduce((sum, element) => sum + element, 0)

  // 問題ごとの解説動画の有無を配列化
  const existMovies: boolean[] = responseProblem.problems.map((problem) => {
    return problem.existMovie
  })

  const imageExplainUrls: ResponseQuestion[][] = responseProblem.problems.map((problem) => problem.questions)

  // 画像パスを問題単位でまとめる
  const imagePaths: ImagePaths = { d: [], m: [], t: [], s: [] }
  responseProblem.problems.forEach((problem) => {
    // 指示文は最初のものだけ登録する
    if (!imagePaths.j && problem.images.j) {
      imagePaths.j = problem.images.j
    }

    // 各画像のパスが存在する場合は追加していく
    if (problem.images.d?.length) {
      imagePaths.d = imagePaths.d?.concat(problem.images.d)
    }
    if (problem.images.m?.length) {
      imagePaths.m = imagePaths.m?.concat(problem.images.m)
    }
    if (problem.images.t?.length) {
      imagePaths.t = imagePaths.t?.concat(problem.images.t)
    }
    if (problem.questions.length) {
      const listImageProblem: any = problem.questions.map((question) => question.images.s)
      imagePaths.s = imagePaths.s?.concat(listImageProblem.flat())
    }
  })

  return {
    isLProblem: responseProblem.isLProblem,
    sProblemIds: sProblemIds,
    sProblemCodes: sProblemCodes,
    totalNumber: totalNumber,
    questionNumber: questionNumber,
    existMovies: existMovies,
    imagePaths: imagePaths,
    imageExplainUrls: imageExplainUrls,
  }
}

// ページタイプ定義
export const PAGE_TYPE = {
  // 演習画面
  DRILL: 'drill',
  // 採点画面
  SCORING_UNIT: 'scoring_unit',
  // 問題結果画面
  RESULT_UNIT: 'result_unit',
  // 結果一覧画面
  RESULT_ALL: 'result_all',
}

// 問題情報
export type Problem = {
  // 大問かどうか
  isLProblem: boolean
  // 小問ID
  sProblemIds: number[]
  // 小問コード
  sProblemCodes: string[]
  // 合計設問数
  totalNumber: number
  // 問題ごとの設問数
  questionNumber: number[]
  // 解説動画の有無
  existMovies: boolean[]
  // 読解文、問題文、解答、解説、指示文等の画像パス
  imagePaths: ImagePaths

  imageExplainUrls: ResponseQuestion[][]
}

const state = {
  // 授業モード
  classModeCode: '',

  // 授業種別
  classCategoryCode: '',

  // 現在ページ
  currentPage: 0,

  // 現在のページタイプ
  pageType: '',

  // 教科コード
  subjectCode: null,

  // 表示する問題情報(複数)
  allProblems: [],

  // 正誤情報(入力、表示共有)
  corrects: [],

  // 問題実施数
  questionCount: 0,

  // 問題正解数
  correctCount: 0,

  // 対象小項目ID
  curriculumSUnitIds: [],

  // 理解度情報
  predictedScores: [],

  // 動画視聴状況
  // 二次元配列で、ページと大問インデックスを使って参照する
  moviePlayed: [],

  // 読み込み完了フラグ
  processed: false,
}

const getters = {
  classModeCode: (state: any) => state.classModeCode,
  classCategoryCode: (state: any) => state.classCategoryCode,
  currentPage: (state: any) => state.currentPage,
  pageType: (state: any) => state.pageType,
  subjectCode: (state: any) => state.subjectCode,
  allProblems: (state: any) => state.allProblems,
  corrects: (state: any) => state.corrects,
  questionCount: (state: any) => state.questionCount,
  correctCount: (state: any) => state.correctCount,
  curriculumSUnitIds: (state: any) => state.curriculumSUnitIds,
  predictedScores: (state: any) => state.predictedScores,
  moviePlayed: (state: any) => state.moviePlayed,
  processed: (state: any) => state.processed,
}

const mutations = {
  setClassModeCode(state: any, payload: any) {
    Vue.prototype.$logger.info('-- mutations setClassModeCode')
    Vue.prototype.$logger.info(payload)

    state.classModeCode = payload
  },

  setClassCategoryCode(state: any, payload: any) {
    Vue.prototype.$logger.info('-- mutations setClassCategoryCode')
    Vue.prototype.$logger.info(payload)

    state.classCategoryCode = payload
  },

  setCurrentPage(state: any, payload: any) {
    Vue.prototype.$logger.info('-- mutations setCurrentPage')
    Vue.prototype.$logger.info(payload)

    state.currentPage = payload
  },

  setPageType(state: any, payload: any) {
    Vue.prototype.$logger.info('-- mutations setPageType')
    Vue.prototype.$logger.info(payload)

    state.pageType = payload
  },

  setSubjectCode(state: any, payload: any) {
    Vue.prototype.$logger.info('-- mutations setSubjectCode')
    Vue.prototype.$logger.info(payload)

    state.subjectCode = payload
  },

  setAllProblems(state: any, payload: any) {
    Vue.prototype.$logger.info('-- mutations setAllProblems')
    Vue.prototype.$logger.info(payload)

    state.allProblems = payload
  },

  setCorrects(state: any, payload: any) {
    Vue.prototype.$logger.info('-- mutations setCorrects')
    Vue.prototype.$logger.info(payload)

    state.corrects = payload
  },

  setCorrectDetail(state: any, payload: any) {
    Vue.prototype.$logger.info('-- mutations setCorrectDetail')
    Vue.prototype.$logger.info(payload)

    if (typeof payload.pageIndex === 'undefined') return
    if (typeof payload.questionIndex === 'undefined') return

    if (
      !state.corrects ||
      !state.corrects[payload.pageIndex] ||
      typeof state.corrects[payload.pageIndex][payload.questionIndex] === 'undefined'
    ) {
      return
    }

    state.corrects[payload.pageIndex][payload.questionIndex] = payload.value
  },

  setQuestionCount(state: any, payload: any) {
    Vue.prototype.$logger.info('-- mutations setQuestionCount')
    Vue.prototype.$logger.info(payload)

    state.questionCount = payload
  },

  setCorrectCount(state: any, payload: any) {
    Vue.prototype.$logger.info('-- mutations setCorrectCount')
    Vue.prototype.$logger.info(payload)

    state.correctCount = payload
  },

  setCurriculumSUnitIds(state: any, payload: any) {
    Vue.prototype.$logger.info('-- mutations setCurriculumSUnitIds')
    Vue.prototype.$logger.info(payload)

    state.curriculumSUnitIds = payload
  },

  setPredictedScores(state: any, payload: any) {
    Vue.prototype.$logger.info('-- mutations setPredictedScores')
    Vue.prototype.$logger.info(payload)

    state.predictedScores = payload
  },

  setMoviePlayed(state: any, payload: any) {
    Vue.prototype.$logger.info('-- mutations setMoviePlayed')
    Vue.prototype.$logger.info(payload)

    state.moviePlayed = payload
  },

  setProcessed(state: any, payload: any) {
    Vue.prototype.$logger.info('-- mutations setProcessed')
    Vue.prototype.$logger.info(payload)

    state.processed = payload
  },
}

const actions = {
  setProblems({ state, commit }: any, payload: ResponseProblem[]) {
    Vue.prototype.$logger.info('-- actions setProblemData')
    Vue.prototype.$logger.info(payload)

    // 使いやすいように切り分けて保持する
    const problems = payload.map((problem) => {
      return parseResponseToProblem(problem)
    })
    commit('setAllProblems', problems)
    if (payload[0].subjectCode === 'sy') {
      payload[0].subjectCode = 'sh'
    }
    commit('setSubjectCode', payload[0].subjectCode)
  },
  setProblemCorrects({ state, commit }: any, payload: ResultProblem[][]) {
    const corrects = payload.map((resultProblems) => {
      return resultProblems.flatMap((resultProblem) => {
        return resultProblem.questions.flatMap((question) => {
          return question.correct
        })
      })
    })
    commit('setCorrects', corrects)
  },
  setMoviePlayed({ state, commit }: any, payload: ResultProblem[][]) {
    const moviePlayed = payload.map((resultProblems) => {
      return resultProblems.map((resultProblem) => {
        return resultProblem.isMoviePlayed
      })
    })
    commit('setMoviePlayed', moviePlayed)
  },
  setResultDrill({ state, commit }: any, payload: ResponseResultDrill) {
    commit('setQuestionCount', payload.questionCount)
    commit('setCorrectCount', payload.correctCount)
    commit('setCurriculumSUnitIds', payload.curriculumSUnitIds)
    commit('setPredictedScores', payload.predictedScores)
  },
  setResultProblem({ state, commit }: any, payload: ResponseResultProblem) {
    // 問題数をカウント
    const questionCount = payload.problems.reduce((count, problem) => {
      return count + problem.questions.length
    }, 0)
    // 正解数をカウント
    const correctCount = payload.problems.reduce((count, problem) => {
      const correctQuestionCount = problem.questions.filter((question) => {
        return question.correct
      }).length
      return count + correctQuestionCount
    }, 0)

    commit('setQuestionCount', questionCount)
    commit('setCorrectCount', correctCount)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
