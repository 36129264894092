







import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  inheritAttrs: false,
})
export default class ButtonLink extends Vue {
  @Prop()
  underline?: boolean
  @Prop({ default: '' })
  href?: string

  private clickEvent(key: Event): void {
    if (this.href) {
      const link = document.createElement('a')
      link.setAttribute('href', this.href)
      link.setAttribute('target', '_blank')
      link.click()
      link.remove()
    }
    this.$emit('click', key)
  }
}
