































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class FaceRadio extends Vue {
  @Prop({ default: false })
  disabled?: boolean

  @Prop()
  value!: string

  get selectedValue(): any {
    return this.value
  }

  set selectedValue(value) {
    this.$emit('input', value)
  }
}
