import { Vue } from 'vue-property-decorator'

function parseUrlQuery(query: string | null): { [key: string]: string } | null {
  if (!query) return null

  return query
    .substring(1)
    .split('&')
    .map((p) => p.split('='))
    .reduce((obj, e) => ({ ...obj, [e[0]]: e[1] }), {})
}

const state = {
  // ドリルに関する全てのデータ
  drillData: null,

  // 読解文、問題文、解答、解説、指示文等の画像パス
  imagePaths: [],

  // 読解文、問題文、解答、解説、指示文等の画像URL
  imageUrls: [],

  // 小問毎の音声データURLの2重配列が入る: [[url1, url2, ...], [url1, url2, ...]]
  audioUrls: [],

  // 前のページのページタイプ
  prevPageType: '',

  // 次のページのページタイプ
  nextPageType: '',

  // 前のURL
  prevUrl: '',

  // 次のURL
  nextUrl: '',

  // 大問かどうか。問題ページの場合は1つ、結果ページの場合は複数
  isLProblems: [],

  // 読み込み完了フラグ
  processed: false,

  // 各ページの設問数
  questionNumber: [],

  // 各ページの大問毎の設問数
  questionNumberWithSProblem: [],

  // 正誤入力情報
  correctInput: [],

  // 正誤入力結果
  correctResult: [],

  // 大問・小問ID
  sProblemIds: [],

  // 小問コード
  sProblemCodes: [],

  // 問題名(入試の場合のみ取得可能)
  sProblemNames: [],

  // 解答形式
  answerFormat: [],

  // 解答選択肢
  selectTags: [],

  // 問題テキスト
  questionText: [],

  // 解答テキスト
  answerText: [],

  // 入力または選択したテキスト
  inputSelectText: [],

  // 声掛けアラートで表示するどの画面にいるかの情報
  noticeCurrentPageText: '',

  // 理解度下限
  predictedScoreMin: 0,

  // 理解度上限
  predictedScoreMax: 0,

  // 終了条件
  endCondition: {},
  isClassCategoryCodeRK: false,
}

const getters = {
  drillData: (state: any) => state.drillData,
  imagePaths: (state: any) => state.imagePaths,
  imageUrls: (state: any) => state.imageUrls,
  audioUrls: (state: any) => state.audioUrls,
  prevPageType: (state: any) => state.prevPageType,
  nextPageType: (state: any) => state.nextPageType,
  prevUrl: (state: any) => state.prevUrl,
  nextUrl: (state: any) => state.nextUrl,
  isLProblems: (state: any) => state.isLProblems,
  processed: (state: any) => state.processed,
  questionNumber: (state: any) => state.questionNumber,
  questionNumberWithSProblem: (state: any) => state.questionNumberWithSProblem,
  correctInput: (state: any) => state.correctInput,
  correctResult: (state: any) => state.correctResult,
  sProblemIds: (state: any) => state.sProblemIds,
  sProblemCodes: (state: any) => state.sProblemCodes,
  sProblemNames: (state: any) => state.sProblemNames,
  sProblemIdForQuestions: (state: any) => state.sProblemIdForQuestions,
  answerFormat: (state: any) => state.answerFormat,
  selectTags: (state: any) => state.selectTags,
  questionText: (state: any) => state.questionText,
  answerText: (state: any) => state.answerText,
  inputSelectText: (state: any) => state.inputSelectText,
  noticeCurrentPageText: (state: any) => state.noticeCurrentPageText,
  predictedScoreMin: (state: any) => state.predictedScoreMin,
  predictedScoreMax: (state: any) => state.predictedScoreMax,
  endCondition: (state: any) => state.endCondition,
  isClassCategoryCodeRK: (state: any) => state.isClassCategoryCodeRK,
}

const mutations = {
  setDrillData(state: any, payload: any) {
    Vue.prototype.$logger.info('-- mutations setResults')
    state.drillData = payload
  },

  setImagePaths(state: any, payload: any) {
    Vue.prototype.$logger.info('-- mutations setImagePaths')
    Vue.prototype.$logger.info(payload)

    state.imagePaths = payload
  },

  setImageUrls(state: any, payload: any) {
    Vue.prototype.$logger.info('-- mutations setImageUrls')
    Vue.prototype.$logger.info(payload)

    state.imageUrls = payload
  },

  setAudioUrls(state: any, payload: any) {
    Vue.prototype.$logger.info('-- mutations setAudioUrls')
    Vue.prototype.$logger.info(payload)

    state.audioUrls = payload
  },

  setPrevPageType(state: any, payload: string) {
    Vue.prototype.$logger.info(`-- mutations setPrevPageType ${payload}`)

    if (!payload) payload = ''

    state.prevPageType = payload
  },

  setNextPageType(state: any, payload: string) {
    Vue.prototype.$logger.info(`-- mutations setNextPageType ${payload}`)

    if (!payload) payload = ''

    state.nextPageType = payload
  },

  setPrevUrl(state: any, payload: string) {
    Vue.prototype.$logger.info(`-- mutations setPrevUrl ${payload}`)

    if (!payload) return

    state.prevUrl = `/student/drill${payload}`
  },

  setNextUrl(state: any, payload: string) {
    Vue.prototype.$logger.info(`-- mutations setNextUrl ${payload}`)
    Vue.prototype.$logger.info(`-- nextPageType ${state.nextPageType}`)

    if (!payload) return

    // TODO 演習や宿題、確認テストによる次のページタイプによって出しわけを追加
    switch (state.nextPageType) {
      case 'question':
        Vue.prototype.$logger.info(`-- nextUrl ${state.nextUrl}`)
        state.nextUrl = `/student/drill${payload}`
        break
      case 'scoring_all':
      case 'scoring_unit':
        state.nextUrl = `/student/drill/scoring${payload}`
        break
      case 'result_all':
      case 'result_unit':
        state.nextUrl = `/student/drill/result${payload}`
    }

    Vue.prototype.$logger.info(`-- nextUrl ${state.nextUrl}`)
  },

  setNextUrlDirect(state: any, payload: string) {
    Vue.prototype.$logger.info(`-- mutations setNextUrlDirect ${payload}`)
    if (!payload) return
    state.nextUrl = payload
  },

  setIsLProblems(state: any, payload: boolean) {
    Vue.prototype.$logger.info(`-- mutations setIsLProblems ${payload}`)

    state.isLProblems = payload
  },

  setProcessed(state: any, payload: any) {
    Vue.prototype.$logger.info('-- mutations setProcessed')
    state.processed = payload
  },

  setQuestionNumber(state: any, payload: any) {
    Vue.prototype.$logger.info('-- mutations setQuestionNumber')
    Vue.prototype.$logger.info(payload)

    state.questionNumber = payload
  },

  setQuestionNumberWithSProblem(state: any, payload: any) {
    Vue.prototype.$logger.info('-- mutations setQuestionNumberWithSProblem')
    Vue.prototype.$logger.info(payload)

    state.questionNumberWithSProblem = payload
  },

  setCorrectInput(state: any, payload: any) {
    Vue.prototype.$logger.info('-- mutations setCorrectInput')
    Vue.prototype.$logger.info(payload)

    state.correctInput = payload
  },

  setCorrectInputDetail(state: any, payload: any) {
    Vue.prototype.$logger.info('-- mutations setCorrectInputDetail')
    Vue.prototype.$logger.info(payload)

    if (typeof payload.pageIndex === 'undefined') return
    if (typeof payload.questionIndex === 'undefined') return

    if (
      !state.correctInput ||
      !state.correctInput[payload.pageIndex] ||
      typeof state.correctInput[payload.pageIndex][payload.questionIndex] === 'undefined'
    ) {
      return
    }

    state.correctInput[payload.pageIndex][payload.questionIndex] = payload.value
  },

  setCorrectResult(state: any, payload: any) {
    Vue.prototype.$logger.info('-- mutations setCorrectResult')
    Vue.prototype.$logger.info(payload)

    state.correctResult = payload
  },

  setSProblemIds(state: any, payload: any) {
    Vue.prototype.$logger.info('-- mutations setSProblemIds')
    Vue.prototype.$logger.info(payload)

    state.sProblemIds = payload
  },

  setSProblemCodes(state: any, payload: any) {
    Vue.prototype.$logger.info('-- mutations setSProblemCodes')
    Vue.prototype.$logger.info(payload)

    state.sProblemCodes = payload
  },

  setSProblemNames(state: any, payload: any) {
    Vue.prototype.$logger.info('-- mutations setSProblemNames')
    Vue.prototype.$logger.info(payload)

    state.sProblemNames = payload
  },

  setSProblemIdsForQuestions(state: any, payload: any) {
    Vue.prototype.$logger.info('-- mutations setSProblemIdsForQuestions')
    Vue.prototype.$logger.info(payload)

    state.sProblemIdForQuestions = payload
  },

  setAnswerFormat(state: any, payload: any) {
    Vue.prototype.$logger.info('-- mutations setAnswerFormat')
    Vue.prototype.$logger.info(payload)

    state.answerFormat = payload
  },

  setSelectTags(state: any, payload: any) {
    Vue.prototype.$logger.info('-- mutations setSelectTags')
    Vue.prototype.$logger.info(payload)

    state.selectTags = payload
  },

  setQuestionText(state: any, payload: any) {
    Vue.prototype.$logger.info('-- mutations setQuestionText')
    Vue.prototype.$logger.info(payload)

    state.questionText = payload
  },

  setAnswerText(state: any, payload: any) {
    Vue.prototype.$logger.info('-- mutations setAnswerText')
    Vue.prototype.$logger.info(payload)

    state.answerText = payload
  },

  setInputSelectText(state: any, payload: any) {
    Vue.prototype.$logger.info('-- mutations setInputSelectText')
    Vue.prototype.$logger.info(payload)

    state.inputSelectText = payload
  },

  setNoticeCurrentPageText(state: any, payload: string) {
    Vue.prototype.$logger.info(`-- mutations setNoticeCurrentPageText ${payload}`)

    state.noticeCurrentPageText = payload
  },

  setPredictedScoreMin(state: any, payload: number) {
    Vue.prototype.$logger.info('-- mutations setPredictedScoreMin')
    Vue.prototype.$logger.info(payload)

    state.predictedScoreMin = payload
  },

  setPredictedScoreMax(state: any, payload: number) {
    Vue.prototype.$logger.info('-- mutations setPredictedScoreMax')
    Vue.prototype.$logger.info(payload)

    state.predictedScoreMax = payload
  },

  setEndCondition(state: any, payload: any) {
    Vue.prototype.$logger.info('-- mutations setEndCondition')
    Vue.prototype.$logger.info(payload)

    state.endCondition = payload
  },
  setIsClassCategoryCodeRK(state: any, payload: any) {
    Vue.prototype.$logger.info('-- mutations setIsClassCategoryCodeRK')
    Vue.prototype.$logger.info(payload)

    state.isClassCategoryCodeRK = payload
  },
}

const actions = {
  setDrillData({ state, commit }: any, payload: any) {
    Vue.prototype.$logger.info('-- actions setDrillData')

    // Web APIからの全取得結果を格納
    commit('setDrillData', payload)

    // コンポーネントごとに使いやすいように切り分けておく

    // ページタイプ
    commit('setPrevPageType', parseUrlQuery(payload.prevUrl)?.pageType || '')
    commit('setNextPageType', parseUrlQuery(payload.nextUrl)?.pageType || '')

    // 画像パス
    const imagePaths = payload.data.map((item: any) => item.imagePath)
    commit('setImagePaths', imagePaths)

    // 音声URL配列
    const audioUrls = payload.data.map((item: any) => item.audios)
    commit('setAudioUrls', audioUrls)

    // 前のURL
    commit('setPrevUrl', payload.prevUrl)

    // 次のUTL
    commit('setNextUrl', payload.nextUrl)

    // 大問かどうか
    const isLProblems = payload.data.map((item: any) => item.isLProblem)
    commit('setIsLProblems', isLProblems)

    // 各ページの設問数
    const questionNumber = payload.data.map((item: any) =>
      item.problems.reduce((sum: number, element: any) => {
        return sum + element.number
      }, 0)
    )
    commit('setQuestionNumber', questionNumber)

    // 各ページの大問毎の設問数
    const questionNumberWithSProblem = payload.data.map((item: any) =>
      item.problems.map((problem: any) => problem.number)
    )
    commit('setQuestionNumberWithSProblem', questionNumberWithSProblem)

    // 正誤入力する情報の箱
    const correctInput = payload.data.map((item: any) => {
      const sum = item.problems.reduce((sum: number, element: any) => {
        return sum + element.number
      }, 0)

      return new Array(sum).fill(null)
    })
    commit('setCorrectInput', correctInput)

    // 正誤入力した結果
    const correctResult = payload.data.map((item: any) => item.problems.map((problem: any) => problem.results).flat())
    commit('setCorrectResult', correctResult)

    // 大問・小問ID
    const sProblemIds = payload.data.map((item: any) => item.problems.map((problem: any) => problem.sProblemId))
    commit('setSProblemIds', sProblemIds)

    // 小問コード
    const sProblemCodes = payload.data.map((item: any) => item.problems.map((problem: any) => problem.sProblemCode))
    commit('setSProblemCodes', sProblemCodes)

    // 問題名(入試のみ取得可能)
    const sProblemNames = payload.data.map((item: any) => item.problems.map((problem: any) => problem.sProblemName))
    commit('setSProblemNames', sProblemNames)

    // 大問・小問ID 設問と同構造で用意
    const sProblemIdForQuestions = payload.data.map((item: any) =>
      // 設問数分対応した大問・小問IDを生成
      item.problems
        .map((problem: any) => {
          if (problem.results) {
            return new Array(problem.results.length).fill({
              sProblemId: problem.sProblemId,
              sProblemName: problem.sProblemName,
              existMovie: problem.existMovie,
              isMoviePlayed: problem.isMoviePlayed,
              resultsCount: problem.results.length,
            })
          }
        })
        .flat()
    )
    commit('setSProblemIdsForQuestions', sProblemIdForQuestions)

    // answerFormat
    const answerFormat = payload.data.map((item: any) => item.problems.map((problem: any) => problem.answerFormat))
    commit('setAnswerFormat', answerFormat)

    // selectTags
    const selectTags = payload.data.map((item: any) => item.problems.map((problem: any) => problem.selectTag))
    commit('setSelectTags', selectTags)

    // 各ページの大問毎の設問数分解答の配列を作成
    const inputSelectText = payload.data.map(
      (item: any) =>
        item.problems
          .map((problem: any) => {
            let result = new Array(problem.number).fill('')
            // 既にデータベースに入力データが保存済みの場合は入力済みデータを利用する
            if (problem.inputs) result = problem.inputs
            return result
          })
          .flat() // 1ページ単位ではフラットにする
    )
    commit('setInputSelectText', inputSelectText)

    // answerText
    const answerText = payload.data.map((item: any) => item.problems.map((problem: any) => problem.answerText).flat())
    commit('setAnswerText', answerText)

    // 理解度下限
    commit('setPredictedScoreMin', payload.predictedScores?.min || 0)

    // 理解度上限
    commit('setPredictedScoreMax', payload.predictedScores?.max || 0)

    // 終了条件
    commit('setEndCondition', payload.endCondition || {})
    commit('setIsClassCategoryCodeRK', payload.isClassCategoryCodeRK || true)
  },

  setAssistDrillData({ state, commit }: any, payload: any) {
    Vue.prototype.$logger.info('-- actions setAssistDrillData')

    // answerFormat
    const answerFormat = payload.data.map((item: any) => item.problems.map((problem: any) => problem.answerFormat))
    commit('setAnswerFormat', answerFormat)

    // selectTags
    const selectTags = payload.data.map((item: any) => item.problems.map((problem: any) => problem.selectTags))
    commit('setSelectTags', selectTags)

    // questionText
    const questionText = payload.data.map((item: any) => item.problems.map((problem: any) => problem.questionText))
    commit('setQuestionText', questionText)

    // answerText
    const answerText = payload.data.map((item: any) => item.problems.map((problem: any) => problem.answerText))
    commit('setAnswerText', answerText)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
