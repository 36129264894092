









import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import HeaderModal from '@/components/atoms/quiz/HeaderModal.vue'
import ModalBase from '@/components/molecules/ModalBase.vue'
import ModalBaseMethod from '@/components/molecules/ModalBaseMethod.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import BodyModal from '@/components/atoms/quiz/BodyModal.vue'

@Component({
  components: {
    HeaderModal,
    BodyModal,
    ModalBase,
    ButtonBase,
  },
})
export default class ModalShowText extends Mixins(ModalBaseMethod) {
  private get status() {
    return this.$store.getters['modal/status']
  }
  private get message() {
    return this.status?.message || ''
  }
  @Watch('status', { deep: true })
  watchIsOpen() {
    if (this.status.isOpen) {
      this.show()
    } else {
      this.hide()
    }
  }
}
