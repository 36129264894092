




























import { Component, Mixins, Prop, Ref, Vue } from 'vue-property-decorator'
import ButtonLink from '@/components/atoms/quiz/ButtonLink.vue'
import BaseQuiz from '@/mixins/quiz/BaseQuiz'
import ModalShowImage from '@/components/molecules/quiz/ModalShowImage.vue'

@Component({
  components: {
    ButtonLink,
    ModalShowImage,
  },
})
export default class Menu extends Mixins(BaseQuiz) {
  @Ref()
  private inputRef!: HTMLInputElement

  @Ref()
  private modalImgRef!: ModalShowImage

  @Prop()
  private clickMenu!: (callback: any) => void

  private listMenu = [
    {
      id: 1,
      link: '/quiz/notices',
      text: 'お知らせ',
    },
    {
      id: 2,
      linkImg: '/quiz-assets/img-menu/FAQ.png',
      text: '使い方・よくある質問',
    },
    {
      id: 3,
      link: '/quiz/reset-study',
      text: '学習実績の初期化',
    },
    {
      id: 4,
      link: '/quiz/remove-account',
      text: '退会',
    },
  ]

  private openImage(link: string) {
    if (!link) {
      return
    }
    this.modalImgRef.openFullscreen(link)
  }

  private links = [
    {
      id: 1,
      link: 'https://www.corp-gakken.co.jp/privacypolicy/',
      text: 'プライバシーポリシー',
    },
    {
      id: 2,
      link: 'https://contact.gakken.jp/user/op_enquete.gsp?sid=1283&mid=000755uY&hid=3WAoK8q13_0',
      text: 'お問い合わせ',
    },
    {
      id: 3,
      linkImg: '/quiz-assets/img-menu/term-service.png',
      text: '利用規約',
    },
  ]

  private transition(item: any) {
    if (item.linkImg) {
      return this.openImage(item.linkImg)
    }
    this.clickMenu(() => {
      if (item.link) {
        this.push(item.link, item.text)
      }
      this.toggleMenu()
    })
  }
  private get currentPage() {
    return this.$route.path
  }
  private toggleMenu() {
    this.inputRef.click()
  }
}
