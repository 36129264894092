const state = {
  activeSubjects: '',
}

const getters = {
  activeSubjects: (state: any) => state.activeSubjects,
}

const mutations = {
  setActiveSubjects(state: any, payload: any[]) {
    state.activeSubjects = [...payload]
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
}
