










import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ModalBase extends Vue {
  // モーダル表示幅 - 必須
  @Prop({ default: 400 })
  width!: number

  // モーダル表示高さ - 必須
  @Prop({ default: 300 })
  height!: number

  // タイトル表示文字列
  @Prop()
  title?: string

  @Prop()
  handleClose?: () => void

  // タイトル高さ
  // タイトル表示文字列を指定した場合のみ有効
  @Prop({ default: 50 })
  headerHeight?: number

  // タイトル文字位置
  @Prop({ default: 'center' })
  headerTextAlign?: string

  // 背景クリック時にモーダルを閉じるか否か(ライブラリオプション)
  @Prop({ default: true })
  clickToClose?: boolean

  // 閉じるボタンを表示するか
  @Prop({ default: true })
  closeButton?: boolean

  private hash = this.$uuid.v4()

  hide() {
    this.$modal.hide(this.hash)
  }
  show() {
    this.$modal.show(this.hash)
  }

  get setStyle(): { [key: string]: string } {
    return {
      '--total-height': `${this.height}px`,
      '--header-height': `${this.title ? this.headerHeight : 0}px`,
      '--header-text-align': `${this.headerTextAlign}`,
      '--total-width': `${this.width}px`,
    }
  }
}
