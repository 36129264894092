import _Vue from 'vue'

export default {
  install(Vue: typeof _Vue): void {
    const setSozo = function (isSozo: boolean): void {
      Vue.prototype.$cookies.set('gdlsSozo', { isSozo: isSozo })
    }
    const isSozo = function (): boolean {
      const isStudentOfSouzouAcademy = Vue.prototype.$cookies.get('dataGdls')?.isStudentOfSouzouAcademy
      const cookie = Vue.prototype.$cookies.get('gdlsSozo')
      if (!cookie) return false

      return cookie.isSozo && isStudentOfSouzouAcademy
    }

    const isPageSozo = function (): boolean {
      const cookie = Vue.prototype.$cookies.get('gdlsSozo')
      if (!cookie) return false
      return cookie.isSozo
    }

    const setV3 = function (isV3: boolean, isSozo = false): void {
      Vue.prototype.$cookies.set('gdlsV3', { isV3: isV3 })
      setSozo(isSozo)
    }
    const isV3 = function (): boolean {
      const cookie = Vue.prototype.$cookies.get('gdlsV3')
      if (!cookie) return false

      return cookie.isV3
    }
    const setYGC = function (isYGC: boolean): void {
      Vue.prototype.$cookies.set('gdlsYGC', { isYGC: isYGC })
    }
    const isYGC = function (): boolean {
      const cookie = Vue.prototype.$cookies.get('gdlsYGC')
      if (!cookie) return false

      return cookie.isYGC
    }

    Vue.prototype.$gdlsCookiesV3 = { setV3, isV3, setSozo, isSozo, isPageSozo, setYGC, isYGC }
  },
}
