import _Vue from 'vue'

/**
 * console.logなどのラッパーメソッド
 * .envファイルの設定を参照し、本番環境ではconsole表示しない
 * console.log → Vue.prototype.$logger.log
 */
export default {
  install(Vue: typeof _Vue): void {
    const check = function (response: any) {
      return process.env.VUE_APP_DISPLAY_CONSOLE_LOG == 'true'
        ? response
        : function () {
            return
          }
    }
    const log = check(console.log.bind(console))
    const info = check(console.info.bind(console))
    const warn = check(console.warn.bind(console))
    const error = check(console.error.bind(console))

    Vue.prototype.$logger = { log, info, warn, error }
  },
}
