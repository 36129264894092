const state = {
  listMode: [],
}

const getters = {
  listMode: (state: any) => state.listMode,
}

const mutations = {
  setListMode(state: any, payload: any[]) {
    state.listMode = [...payload]
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
}
