import _Vue from 'vue'

export default {
  install(Vue: typeof _Vue): void {
    const overlayId = 'overlayLoading'

    const start = function (): void {
      const overlay = document.createElement('div')
      overlay.id = overlayId
      const loadingImage = document.createElement('div')
      loadingImage.id = 'loadingImage'
      overlay.appendChild(loadingImage)
      document.body.appendChild(overlay)
    }
    const startBgWhite = function (): void {
      const overlay = document.createElement('div')
      overlay.id = overlayId
      overlay.classList.add('bgWhite')
      const loadingImage = document.createElement('div')
      loadingImage.id = 'loadingImage'
      overlay.appendChild(loadingImage)
      document.body.appendChild(overlay)
    }
    const startWhite = function (): void {
      const overlay = document.createElement('div')
      overlay.id = overlayId
      const loadingImage = document.createElement('div')
      loadingImage.id = 'loadingWhiteImage'
      overlay.appendChild(loadingImage)
      document.body.appendChild(overlay)
    }
    const complete = function (): void {
      const overlay = document.getElementById(overlayId)
      if (overlay) {
        overlay.remove()
      }
    }
    Vue.prototype.$loading = { start, startWhite, complete, startBgWhite }
  },
}
