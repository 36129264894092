import { KEY_ANSWERS } from '@/constants'
import {
  CurriculumType,
  ResultUnitType,
  AnswerType,
  QuestionCode,
  AnswerStoreType,
  QuestionStoreType,
} from '@/types/quiz/units'

const checkAnswerCorrect = (answer: AnswerType) => {
  return answer.isCorrectText || answer.answerFormatCode === QuestionCode.FLASHCARD
}
/**
 * キューの追加が必要な場合はこのstateに追加する
 */

interface StateQuestion {
  curriculum: null | CurriculumType
  questions: QuestionStoreType[]
  currentQuestion: number
  answers: AnswerStoreType[]
  correctCount: number
  inCorrectCount: number
  totalQuestion: number
  result: null | ResultUnitType
  submitSuccess: string | boolean
  totalFlashCard: number
}

const state: StateQuestion = {
  curriculum: null,
  questions: [],
  currentQuestion: 1,
  answers: [],
  correctCount: 0,
  inCorrectCount: 0,
  totalQuestion: 0,
  result: null,
  submitSuccess: 'pendding',
  totalFlashCard: 0,
}

const getters = {
  title: (state: StateQuestion) => state.curriculum?.title,
  submitSuccess: (state: StateQuestion) => state.submitSuccess,
  curriculum: (state: StateQuestion) => state.curriculum,
  result: (state: StateQuestion) => state.result,
  questions: (state: StateQuestion) => state.questions,
  currentQuestion: (state: StateQuestion) => state.currentQuestion,
  answers: (state: StateQuestion) => state.answers,
  correctCount: (state: StateQuestion) => state.correctCount,
  inCorrectCount: (state: StateQuestion) => state.inCorrectCount,
  totalQuestion: (state: StateQuestion) => state.totalQuestion,
  answerCurrent: (state: StateQuestion) => state.questions?.[state.currentQuestion - 1].answers || null,
  questionCurrent: (state: StateQuestion) => state.questions?.[state.currentQuestion - 1],
  isEnd: (state: StateQuestion) => state.currentQuestion > state.totalQuestion,
  onlyFlashCard: (state: StateQuestion) =>
    state.questions.every((item) => item.answerFormatCode === QuestionCode.FLASHCARD),
  totalFlashCard: (state: StateQuestion) => state.totalFlashCard,
}

const mutations = {
  setCurriculum(state: StateQuestion, payload: CurriculumType) {
    state.curriculum = payload
    if (!payload) {
      ;(state.questions = []), (state.totalQuestion = 0)
      state.totalFlashCard = 0
      return
    }
    state.questions = payload.problems.map(
      (problem) =>
        ({
          images: problem.images,
          audios: problem.audios,
          isBookmarked: problem.isBookmarked,
          answers: problem.questions[0].contents,
          questionTextContent: problem.questions[0].questionTextContent,
          questionTextExplain: problem.questions[0].questionTextExplain,
          questionCode: problem.questions[0].questionCode,
          answerFormatCode: problem.questions[0].contents[0].answerFormatCode,
          sProblemId: problem.sProblemId,
          sProblemCode: problem.sProblemCode,
        } as QuestionStoreType)
    )
    state.totalQuestion = payload?.problems?.length || 0
    state.totalFlashCard = state.questions.filter((item) => item.answerFormatCode === QuestionCode.FLASHCARD).length
  },

  setResult(state: StateQuestion, payload: ResultUnitType) {
    state.result = payload
  },

  setAnswers(state: StateQuestion, answers: AnswerStoreType[]) {
    let countCorrect = 0
    answers.forEach((item) => {
      if (checkAnswerCorrect(item.yourChoose)) {
        countCorrect++
      }
    })
    state.answers = answers
    state.currentQuestion = answers.length + 1
    state.correctCount = countCorrect
    state.inCorrectCount = answers.length - countCorrect
  },
  selectAnswer(state: any, answer: AnswerType) {
    if (state.currentQuestion > state.totalQuestion || !answer) {
      return
    }
    if (checkAnswerCorrect(answer)) {
      state.correctCount++
    } else {
      state.inCorrectCount++
    }
    const question = state.questions[state.currentQuestion - 1]
    state.answers.push({
      ...question,
      yourChoose: answer,
    })
    localStorage.setItem(KEY_ANSWERS, JSON.stringify(state.answers))
    state.currentQuestion++
  },

  setSubmitSuccess(state: StateQuestion, status: boolean | string) {
    state.submitSuccess = status
  },
}

const actions = {
  setCurriculum({ commit }: any, payload: any): any {
    commit('setCurriculum', payload)
  },

  setResult({ commit }: any, payload: any): any {
    commit('setResult', payload)
  },

  setAnswers({ commit }: any, payload: any): any {
    commit('setAnswers', payload)
  },

  selectAnswer({ commit }: any, payload: any) {
    commit('selectAnswer', payload)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
