import { Vue } from 'vue-property-decorator'
import ActionCable from 'actioncable'

const state = {
  cable: null,
  channel: null,
  channelName: 'LessonChannel',
  channelAction: 'lessonAction',
  messages: [],
  messageChannel: null,
  lessonId: null,
  justFinishedLessonId: null,
  isConnected: false, // コネクションが確立されているか
  isConnectedWatched: false, // コネクションのwatch済みかどうか
  checkedInLesson: null, // 授業中かどうか判定済みかどうか
  disconnectedCount: 0, // disconnectedが連続で何回きたかカウント
}

const getters = {
  cable: (state: any) => {
    return state.cable
  },
  messages: (state: any) => state.messages,
  messageChannel: (state: any) => state.messageChannel,
  lessonId: (state: any) => state.lessonId,
  justFinishedLessonId: (state: any) => state.justFinishedLessonId,
  isConnected: (state: any) => state.isConnected,
  isConnectedWatched: (state: any) => state.isConnectedWatched,
  checkedInLesson: (state: any) => state.checkedInLesson,
  disconnectedCount: (state: any) => state.disconnectedCount,
}

const mutations = {
  setLessonId(state: any, payload: number) {
    Vue.prototype.$logger.info(`-- LessonActionCable setLessonId ${payload}`)

    state.lessonId = payload
  },

  setJustFinishedLessonId(state: any, payload: number) {
    Vue.prototype.$logger.info(`-- LessonActionCable setJustFinishedLessonId ${payload}`)

    state.justFinishedLessonId = payload
  },

  addMessage(state: any, message: string) {
    state.messages.push(message)
  },

  initCable(state: any) {
    if (state.cable != null) return
    Vue.prototype.$logger.info('-- LessonActionCable initCable')

    const auth = Vue.prototype.$cookies.get('authGdls')
    // コネクション確立
    state.cable = ActionCable.createConsumer(
      `${process.env.VUE_APP_ACTION_CABLE_URL}cable/?access-token=${auth?.accessToken}&uid=${auth?.uid}&client=${auth?.client}`
    )
  },

  setIsConnected(state: any, payload: boolean) {
    Vue.prototype.$logger.info(`-- LessonActionCable setIsConnected ${payload}`)

    state.isConnected = payload
  },

  setIsConnectedWatched(state: any, payload: boolean) {
    Vue.prototype.$logger.info(`-- LessonActionCable setIsConnectedWatched ${payload}`)

    state.isConnectedWatched = payload
  },

  setCheckedInLesson(state: any, payload: boolean) {
    Vue.prototype.$logger.info(`-- LessonActionCable setCheckedInLesson ${payload}`)

    state.checkedInLesson = payload
  },

  setDisconnectedCount(state: any, payload: number) {
    Vue.prototype.$logger.info(`-- LessonActionCable setDisconnectedCount ${payload}`)

    state.disconnectedCount = payload
  },

  incrementDisconnectedCount(state: any) {
    Vue.prototype.$logger.info(`-- LessonActionCable incrementDisconnectedCount`)

    state.disconnectedCount++
  },
}

const actions = {
  async subscriptions({ state, commit, dispatch }: any) {
    if (state.lessonId == null) return
    if (state.messageChannel != null) return
    Vue.prototype.$logger.info('-- LessonActionCable subscriptions')

    state.messageChannel = await state.cable.subscriptions.create(
      { channel: state.channelName, lessonId: state.lessonId },
      {
        // サブスクリプションが作成されると1度呼び出される
        initialized() {
          Vue.prototype.$logger.info('-- LessonActionCable subscriptions initialized')
        },
        // サブスクリプションがサーバーで利用可能になると呼び出される
        connected() {
          Vue.prototype.$logger.info('-- LessonActionCable subscriptions connected')
          // コネクション確立後に実行する処理を実行
          dispatch('afterConnected')
          // disconnectedCountを0にする
          commit('setDisconnectedCount', 0)
        },
        subscribe() {
          Vue.prototype.$logger.info('-- LessonActionCable subscriptions subscribe')
        },
        unsubscribe() {
          Vue.prototype.$logger.info('-- LessonActionCable subscriptions unsubscribe')
        },
        // WebSocketコネクションがクローズすると呼び出される
        disconnected(data: any) {
          Vue.prototype.$logger.info('-- LessonActionCable subscriptions disconnected')
          Vue.prototype.$logger.info(data)

          // disconnectedCountを加算
          commit('incrementDisconnectedCount')
          Vue.prototype.$logger.log(state.disconnectedCount)
          if (state.disconnectedCount < 3) return

          // ログアウト状態になった等で認証が切れて切断された場合は購読を終了する
          // ! 現状は一つの授業のリアルタイム通信のみを考慮しているため複数の通信を行う場合は要拡張
          // ! 現状は再接続する場合はリロードのみなので画面表示中に再接続をする必要がある場合は検討しないといけない
          // 全ての購読を解除する。不要かもしれないが念のため解除しておく
          dispatch('forgetAllChannel')
          // WebSocket通信を切断
          state.cable.disconnect()

          // コネクションクローズ後に実行する処理を実行
          dispatch('afterDisconnected')
        },
        // サブスクリプションがサーバーで却下されると呼び出される
        rejected() {
          Vue.prototype.$logger.info('-- LessonActionCable subscriptions rejected')
        },
        received: (data: any) => {
          Vue.prototype.$logger.info('-- LessonActionCable subscriptions received')
          Vue.prototype.$logger.info(data)
          commit('addMessage', data)

          if (data.messageType === 'stopStreams') {
            // 授業終了の通知を受けた場合は購読を終了する
            // ! 現状は一つの授業のリアルタイム通信のみを考慮しているため複数の通信を行う場合は要拡張
            // 全ての購読を解除する。不要かもしれないが念のため解除しておく
            dispatch('forgetAllChannel')
            // WebSocket通信を切断
            state.cable.disconnect()
          }
        },
      }
    )
  },

  perform({ state, commit }: any, payload: { messageType: string; data: any }) {
    Vue.prototype.$logger.info('-- LessonActionCable perform')
    if (state.lessonId == null) return
    if (state.messageChannel == null) return
    Vue.prototype.$logger.info('-- LessonActionCable perform2')

    state.messageChannel.perform(state.channelAction, {
      ...payload,
      lessonId: state.lessonId,
    })
  },

  /**
   * コネクション確立後に行う処理
   * ? キューに溜めた処理を実行する（かもしれない）
   *
   * @param param0
   */
  afterConnected({ commit }: any) {
    Vue.prototype.$logger.info('-- LessonActionCable afterConnected')
    commit('setIsConnected', true)
  },

  afterDisconnected() {
    Vue.prototype.$logger.info('-- LessonActionCable afterDisconnected')
    alert('更新情報が溜まっているため画面を更新してください')
  },

  removeAllChannel({ state }: any) {
    Vue.prototype.$logger.log('-- LessonActionCable removeAllChannel')

    const subscriptions = state.cable.subscriptions['subscriptions']
    subscriptions.forEach(function (subscription: any) {
      state.cable.subscriptions.remove(subscription)
    })
  },

  /**
   * subscriptionsを全て削除する
   * removeAllChannelとの違い、全て削除後にunsubscribeの通知をrailsに送らない
   */
  forgetAllChannel({ state, commit }: any) {
    Vue.prototype.$logger.log('-- LessonActionCable forgetAllChannel')

    const subscriptions = state.cable.subscriptions['subscriptions']
    subscriptions.forEach(function (subscription: any) {
      state.cable.subscriptions.forget(subscription)
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
