








import { Component, Mixins, Prop, Ref, Vue } from 'vue-property-decorator'

@Component
export default class ModalShowImage extends Vue {
  @Ref()
  private dialogRef!: HTMLDivElement

  private src = ''

  public openFullscreen(src: string) {
    this.src = src
    this.dialogRef.classList.add('full-screen')
  }

  public closeFullscreen() {
    this.src = ''
    this.dialogRef.classList.remove('full-screen')
  }
}
