import Vue from 'vue'

export default {
  /**
   * 各エラーイベント検知時のリスナーセット
   * Vueコンポーネント内とそれ以外、また非同期スレッドでのエラーそれぞれを検知し、APIリクエスト
   */
  setErrorEventListeners() {
    // 基本的にVueコンポーネントのライフサイクル内のエラーはここでハンドルできる
    Vue.config.errorHandler = (err, vm, info) => {
      console.log('Captured in Vue.config.errorHandler', err)

      const params = {
        info: info,
        errName: err.name,
        errMessage: err.message,
        errStack: err.stack || '',
        routePath: vm.$route.path,
      }
      this.postClientError(params)
    }

    window.addEventListener('error', (event) => {
      console.log('Captured in error EventListener', event.error)

      const params = {
        info: 'error event',
        errName: event.error.name,
        errMessage: event.error.message,
        errStack: event.error.stack,
        routePath: location.href,
      }
      this.postClientError(params)
    })

    window.addEventListener('unhandledrejection', (event) => {
      console.log('Captured in unhandledrejection EventListener', event.reason)

      // ネットワークエラーの場合、APIへのPOSTが再度ネットワークエラーとなり無限ループの可能性があるのでPOSTしない
      // 503エラー(システム停止中)の場合も同様
      // 423エラー(バッチメンテナンス中)も除外
      if (
        event.reason.message !== 'Network Error' &&
        event.reason.message !== 'Request failed with status code 503' &&
        event.reason.message !== 'Request failed with status code 423'
      ) {
        const params = {
          info: 'unhandledrejection event',
          errName: event.reason.name,
          errMessage: event.reason.message,
          errStack: event.reason.stack,
          routePath: location.href,
        }
        this.postClientError(params)
      }
    })
  },

  /**
   * 共通エラーをハンドルし、APIリクエスト
   * @param params
   */
  postClientError(params: { info: string; errName: string; errMessage: string; errStack: string; routePath: string }) {
    try {
      // APIリクエスト
      Vue.prototype.$http.http.post('/handle_client_error', params)
    } catch (e) {
      console.log(`--errorHandler`, e)
    }
  },
}
