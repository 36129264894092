import { Icon, Scope, Level } from '@/types/teacher/mode'
const SETTING_KEY = 'setting-select'

export const TIME_NEXT_QUESTION = 500
export const KEY_ANSWERS = 'answers'
export const KEY_RESULT_RESPONSE = 'result-response'

const LANG_KEY = 'lang'

const TITLE_HOMEWORK = 'title_homework'

const MODE_DEFAULT: any[] = [
  {
    learning_flows: ['演習'],
    learning_range: {
      type: Scope.GDLS,
      data: {},
    },
    learning_end_condition: {
      type: 1,
      data: {
        '算数/数学': {
          restriction: '問',
          limitThreshold: 5,
        },
        英語: {
          restriction: '問',
          limitThreshold: 5,
        },
        国語: {
          restriction: '問',
          limitThreshold: 1,
        },
        理科: {
          restriction: '問',
          limitThreshold: 5,
        },
        社会: {
          restriction: '問',
          limitThreshold: 5,
        },
      },
    },
    depth_of_trace: Level.UNLIMITED,
    is_random_question: true,
    study_period: { start: null, end: null },
  },
  {
    learning_flows: ['導入動画', '理解度テスト', '演習'],
    learning_range: {
      type: Scope.GDLS,
      data: {},
    },
    learning_end_condition: {
      type: 1,
      data: {
        '算数/数学': {
          restriction: '問',
          limitThreshold: 5,
        },
        英語: {
          restriction: '問',
          limitThreshold: 5,
        },
        国語: {
          restriction: '問',
          limitThreshold: 1,
        },
        理科: {
          restriction: '問',
          limitThreshold: 5,
        },
        社会: {
          restriction: '問',
          limitThreshold: 5,
        },
      },
    },
    depth_of_trace: Level.UNLIMITED,
    is_random_question: false,
    study_period: { start: null, end: null },
  },
]

const ICON_LIST: Icon[] = [
  {
    id: 1,
    value: 'pencil',
    image: require('../../public/img/v3/icon-mode/pencil.svg'),
  },
  {
    id: 2,
    value: 'board',
    image: require('../../public/img/v3/icon-mode/board.svg'),
  },
  {
    id: 3,
    value: 'paper',
    image: require('../../public/img/v3/icon-mode/paper.svg'),
  },
  {
    id: 4,
    value: 'hat',
    image: require('../../public/img/v3/icon-mode/hat.svg'),
  },
  {
    id: 5,
    value: 'flower',
    image: require('../../public/img/v3/icon-mode/flower.svg'),
  },
  {
    id: 6,
    value: 'chart',
    image: require('../../public/img/v3/icon-mode/chart.svg'),
  },
  {
    id: 7,
    value: 'book',
    image: require('../../public/img/v3/icon-mode/book.svg'),
  },
  {
    id: 8,
    value: 'homeschooling',
    image: require('../../public/img/v3/icon-mode/homeschooling.svg'),
  },
  {
    id: 9,
    value: 'hand-pen',
    image: require('../../public/img/v3/icon-mode/hand-pen.svg'),
  },
  {
    id: 10,
    value: 'watermelon',
    image: require('../../public/img/v3/icon-mode/watermelon.svg'),
  },
  {
    id: 11,
    value: 'growth',
    image: require('../../public/img/v3/icon-mode/growth.svg'),
  },
  {
    id: 12,
    value: 'calendar',
    image: require('../../public/img/v3/icon-mode/calendar.svg'),
  },
  {
    id: 13,
    value: 'q',
    image: require('../../public/img/v3/icon-mode/q.svg'),
  },
  {
    id: 14,
    value: 'snowman',
    image: require('../../public/img/v3/icon-mode/snowman.svg'),
  },
  {
    id: 15,
    value: 'maple-leaf',
    image: require('../../public/img/v3/icon-mode/maple-leaf.svg'),
  },
]

export const MAP_NAME_ICON = {
  1: 'pencil',
  2: 'board',
  3: 'paper',
  4: 'hat',
  5: 'flower',
  6: 'chart',
  7: 'book',
  8: 'homeschooling',
  9: 'hand-pen',
  10: 'watermelon',
  11: 'growth',
  12: 'calendar',
  13: 'q',
  14: 'snowman',
  15: 'maple-leaf',
}

const STATUS_NOTE_REPORT = {
  wellDone: 1,
  done: 2,
  uneasy: 3,
  couldNot: 4,
  bug: 5,
  question: 6,
}

const TYPE_NOTE_REPORT = {
  status: 1,
  bug: 2,
  question: 3,
}

const DASHBOARD = {
  default: 1,
  review: 2,
  goal: 3,
  schedule: 4,
}

const PREFIX_URL_NOT_PAGE_STUDY = 'NONE:'

const PAGE_STUDY = [
  'drillV3',
  'drillScoringV3',
  'drillResultUnitV3',
  'drillResultV3',
  'drillV2',
  'drillScoringV2',
  'drillResultUnitV2',
  'drillResultV2',
  'drill',
  'drillScoring',
  'drillResult',
  'AssistMaterialPractice',
  'ExplanatoryVideo',
  'IntroductionVideo',
  'ExplanatoryVideoV2',
  'IntroductionVideoV2',
  'ExplanatoryVideoV3',
  'IntroductionVideoV3',
  'AssistMaterialVidel'
]

const LIST_TEXT_RANDOM = [
  'nameさん、今日は何問演習しようか？',
  'nameさん、今日はどんな一日だった？',
  'nameさん、今日の目標はなぁに？',
  'nameさん、今日は何に挑戦する？',
  'nameさん、今日の授業はなぁに？',
  'nameさん、好きな食べ物はなぁに？',
  'nameさん、お帰り！今日も頑張ろうね。',
  'nameさん、お帰り！一緒に頑張ろうね。',
  'nameさん、お帰り！一緒に勉強しようね。',
  'nameさん、お帰り！演習問題がんばろうね。',
  'nameさん、ようこそ！一緒に成長しようよ。',
  'nameさん、ようこそ！応援しているよ。',
  'nameさん、ようこそ！待ってたよ。',
  'nameさん、ようこそ！準備はいいかな？',
  'nameさん…ボク、サミシカッタヨ。',
  'nameさん…ボク、オナカスイタ。',
  'あきらめたらそこでおしまい！',
  'コツコツやったもの勝ち！',
  'ボク、デンリュウガスキデス。',
  '毎日ログイン毎日演習！',
]

const TEACHER_SETTING_TEXT = '各種設定'

const GRADE_OPTIONS = [
  { text: '小1', value: 's1' },
  { text: '小2', value: 's2' },
  { text: '小3', value: 's3' },
  { text: '小4', value: 's4' },
  { text: '小5', value: 's5' },
  { text: '小6', value: 's6' },
  { text: '中1', value: 't1' },
  { text: '中2', value: 't2' },
  { text: '中3', value: 't3' },
]

const PEN_COLOR = {
  BLACK: '#363636',
  ORANGE: '#E46F21',
  GREEN: '#00A335',
  BLUE: '#4A90E2',
  RED: '#E00B0B'
}

const PEN_COLOR_KEY = {
  BLACK: 'BLACK',
  ORANGE: 'ORANGE',
  GREEN: 'GREEN',
  RED: 'RED',
  BLUE: 'BLUE'
}

const DEFAULT_VALUE_CANVAS = {
  deletedStack: [],
  redoStack: [],
  redoType: [],
  undoStack: [],
  undoType: [],
}

const LINE_WIDTH = {
  THIN: 1,
  MEDIUM: 3,
  ADVAND: 5
}

export {
  SETTING_KEY,
  MODE_DEFAULT,
  ICON_LIST,
  LANG_KEY,
  TITLE_HOMEWORK,
  STATUS_NOTE_REPORT,
  TYPE_NOTE_REPORT,
  DASHBOARD,
  PREFIX_URL_NOT_PAGE_STUDY,
  PAGE_STUDY,
  LIST_TEXT_RANDOM,
  TEACHER_SETTING_TEXT,
  GRADE_OPTIONS,
  PEN_COLOR,
  PEN_COLOR_KEY,
  DEFAULT_VALUE_CANVAS,
  LINE_WIDTH
}
