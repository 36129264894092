







import { Component, Vue } from 'vue-property-decorator'

@Component
export default class TeacherSignInLayout extends Vue {
  private toggleBodyClass(addRemoveClass: string, className: string) {
    const el = document.body

    if (addRemoveClass === 'addClass') {
      el.classList.add(className)
    } else {
      el.classList.remove(className)
    }
  }

  private mounted() {
    this.toggleBodyClass('addClass', 'teacher')
  }

  private destroyed() {
    this.toggleBodyClass('removeClass', 'teacher')
  }
}
