import { Vue } from 'vue-property-decorator'
import { MAP_NAME_ICON } from '@/constants'
import { Characters, DataConfig, TypeDesign } from '@/types/student/design'
/*
  NOTE: comments in this file are fixed aim guide custom design. 
  Developers maintain after don't remove these comments
*/

const INIT_DESIGN: DataConfig = {
  fontSize: '',
  color: '',
  backgroundColor: '',
  sidebar: {
    colorPrimary: '',
    colorSecondary: '',
    colorActive: '',
    colorActiveSecondary: '',
    colorText: '',
  },
  colorBtnStartLearning: {
    start: '',
    end: '',
    color: '',
  },
  colorBtnChangeClass: {
    start: '',
    end: '',
    color: '',
  },
  hide: {},
  onlyShow: {},
  modify: {} as any,
  modifyText: {},
  type: {},
  // define new key and set value empty for this key
}

const DESIGN_DEFAULT = 'default'

//Define button in sidebar
const MAP_NAME_SIDEBAR = {
  iconCorrecting: 'icon_correcting',
  iconExam: 'icon_exam',
  iconHomework: 'icon_homework',
  iconMeasure: 'icon_measure',
  iconMode: 'icon_mode',
  iconPlan: 'icon_plan',
  iconRevise: 'icon_revise',
  iconSchedule: 'icon_calendar',
  iconTimeline: 'icon_timeline',
  iconChallenge: 'icon_exam'
}
export class Design {
  private nameDesign!: number | string
  private logo?: string
  private favicon?: string
  private iconCorrecting?: string
  private iconExam?: string
  private iconChallenge?: string;
  private iconHomework?: string
  private iconMeasure?: string
  private iconMode?: string
  private iconPlan?: string
  private iconRevise?: string
  private iconSchedule?: string
  private iconTimeline?: string
  private characters?: Characters
  private linkCustom?: string
  private linkDefault = `/designs/design_${DESIGN_DEFAULT}`

  // define new key relation image, icon, logo, favicon

  constructor(nameDesign: number | string) {
    this.init(nameDesign)
  }

  private init(nameDesign: number | string, dataConfig?: DataConfig) {
    this.nameDesign = nameDesign
    this.linkCustom = `/designs/design_${nameDesign}`
    if (dataConfig?.modify?.iconSidebar?.isAll) {
      Object.keys(MAP_NAME_SIDEBAR).forEach((key: string) => {
        const nameFile = MAP_NAME_SIDEBAR[key]
        const type = dataConfig?.type?.[key] || 'svg'
        this[key] = `${this.linkCustom}/icon_sidebar/${nameFile}.${type}`
      })
    } else {
      Object.keys(MAP_NAME_SIDEBAR).forEach((key: string) => {
        const nameFile = MAP_NAME_SIDEBAR[key]
        const type = dataConfig?.type?.[key] || 'svg'
        const isModify = dataConfig?.modify?.iconSidebar?.[key]
        this[key] = `${isModify ? this.linkCustom : this.linkDefault}/icon_sidebar/${nameFile}.${type}`
      })
    }
    this.logo = `/designs/design_${nameDesign}/logo.${dataConfig?.type?.logo || 'png'}`
    this.favicon = `/designs/design_${nameDesign}/favicon.${dataConfig?.type?.favicon || 'png'}`
    this.characters = {
      default: this.getLinkCharacter('01', dataConfig),
      hello: this.getLinkCharacter('02', dataConfig),
      blink: this.getLinkCharacter('03', dataConfig),
      hello_blink: this.getLinkCharacter('04', dataConfig),
      sad: this.getLinkCharacter('05', dataConfig),
      error: this.getLinkCharacter('batsu_01', dataConfig),
      correct: this.getLinkCharacter('maru_01', dataConfig),
      excellent: this.getLinkCharacter('maru_02', dataConfig),
    }
    // map path for new key relation image, icon, logo, favicon
  }

  private getLinkCharacter(key: string, dataConfig?: DataConfig) {
    return dataConfig?.hasCustomCharacter
      ? `${this.linkCustom}/characters/${key}.png`
      : `${this.linkDefault}/characters/${key}.png`
  }

  public properties() {
    let dataConfig = INIT_DESIGN
    const isV3 = Vue.prototype.$gdlsCookiesV3.isV3()
    try {
      if (isV3) {
        const _dataConfig = require(`../../public/designs/design_${this.nameDesign}/config.json`)
        dataConfig = { ...dataConfig, ..._dataConfig }
        this.init(this.nameDesign, dataConfig)
      }
    } catch (error) {
      dataConfig = INIT_DESIGN
      this.init(DESIGN_DEFAULT)
    }
    const linkIconDesignDefault = `${this.linkDefault}/icon_sidebar/icon-mode/`
    const linkIconDesignCustom = `${this.linkCustom}/icon_sidebar/icon-mode/`
    return {
      fontSize: dataConfig?.fontSize || 14,
      color: dataConfig?.color || 'black',
      backgroundColor: dataConfig?.backgroundColor || '#f2f5f7',
      logo: this.logo,
      favicon: this.favicon,
      iconSidebar: {
        iconCorrecting: this.iconCorrecting,
        iconExam: this.iconExam,
        iconHomework: this.iconHomework,
        iconMeasure: this.iconMeasure,
        iconMode: this.iconMode,
        iconPlan: this.iconPlan,
        iconRevise: this.iconRevise,
        iconSchedule: this.iconSchedule,
        iconTimeline: this.iconTimeline,
        iconChallenge: this.iconChallenge,
      },
      buttonSidebarPrimary: dataConfig?.sidebar?.colorPrimary || '#DDF0FF',
      buttonSidebarSecond: dataConfig?.sidebar?.colorSecondary || '#FFF2AD',
      buttonColorActiveSidebar: dataConfig?.sidebar?.colorActive || '#4b9ad9',
      buttonColorActiveSidebarSecondary: dataConfig?.sidebar?.colorActiveSecondary || '#4b9ad9',
      colorTextSidebar: dataConfig?.sidebar?.colorText || '#000000',
      colorBtnStartLearning: {
        start: dataConfig?.colorBtnStartLearning?.start || '#E46F21',
        end: dataConfig?.colorBtnStartLearning?.end || '#FBD975',
        color: dataConfig?.colorBtnStartLearning?.color || 'white',
      },
      colorBtnChangeClass: {
        start: dataConfig?.colorBtnChangeClass?.start || '#E46F21',
        end: dataConfig?.colorBtnChangeClass?.end || '#FBD975',
        color: dataConfig?.colorBtnChangeClass?.color || 'white',
      },
      getIconModeCustom: function (id: number | string) {
        const name = MAP_NAME_ICON[id]
        let linkIconMode = linkIconDesignDefault
        if (dataConfig.modify?.iconMode?.[name] || dataConfig.modify?.iconMode?.isAll) {
          linkIconMode = linkIconDesignCustom
        }
        return `${linkIconMode + name}.${dataConfig?.type?.[name] || 'svg'}`
      },
      hide: {
        ...dataConfig.hide,
      },
      onlyShow: { ...dataConfig.onlyShow },
      modifyText: { ...dataConfig.modifyText },
      characters: { ...this.characters },
      // define new key and set value default for this key ...
    } as TypeDesign
  }
}
