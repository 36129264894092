





import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  inheritAttrs: false,
})
export default class ColoredBox extends Vue {
  @Prop()
  radius?: string

  @Prop()
  shadow?: string

  @Prop()
  colortype?: string

  get classObject(): { [key: string]: boolean } {
    const { radius, shadow, colortype } = this
    return {
      // 影
      'colored-box__shadow-default': !shadow,
      'colored-box__shadow-none': shadow === 'none',
      // 角丸指定
      'colored-box__radius-default': !radius,
      'colored-box__radius-none': radius === 'none',
      'colored-box__radius-small': radius === 'small',
      'colored-box__radius-normal': radius === 'normal',
      // 色指定
      'colored-box__color-default': !colortype,
      'colored-box__color-white': colortype === 'white',
      'colored-box__color-lightgray': colortype === 'lightgray',
      'colored-box__color-lightyellow': colortype === 'lightyellow',
      'colored-box__color-cream': colortype === 'cream',
    }
  }

  private clickEvent(key: Event): void {
    this.$emit('click-event', key)
  }
}
