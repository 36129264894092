










import { STATUS_NOTE_REPORT } from '@/constants'
import { Component, Vue } from 'vue-property-decorator'
import BottomPanelReport from '@/components/organisms/v3/BottomPanelReport.vue'

@Component({
  components: {
    BottomPanelReport,
  },
})
export default class StudentHeadmenuLayout extends Vue {
  private toggleBodyClass(addRemoveClass: string, className: string): void {
    const el = document.body

    if (addRemoveClass === 'addClass') {
      el.classList.add(className)
    } else {
      el.classList.remove(className)
    }
  }

  private get showButtonPanel() {
    const currentName = this.$route.name || ''
    const isReportExplanatoryVideo = this.$route.query.isReportExplanatoryVideo || false
    return (
      !['ReportExplanatoryVideo', 'ReportExplanatoryVideoV2', 'ReportExplanatoryVideoV3'].includes(currentName) ||
      isReportExplanatoryVideo
    )
  }

  private get textComment() {
    const role = Vue.prototype.$cookies.get('authGdls').role
    return role === 'student' ? 'メモ' : '生徒のメモ'
  }

  private get typeReport() {
    const type = +this.$route.query?.type || 1
    let text = ''
    switch (type) {
      case STATUS_NOTE_REPORT.wellDone:
        text = 'とてもよくできた'
        break
      case STATUS_NOTE_REPORT.done:
        text = 'できた'
        break
      case STATUS_NOTE_REPORT.uneasy:
        text = '少し不安'
        break
      case STATUS_NOTE_REPORT.couldNot:
        text = 'できなかった'
        break
      case STATUS_NOTE_REPORT.bug:
        text = '不具合を見つけた'
        break
      case STATUS_NOTE_REPORT.question:
        text = '先生に質問したい'
        break
      default:
        text = ''
    }
    text = `「${text}」`
    return text
  }

  private get contentNote() {
    return this.textComment + ': ' + this.$route.query.content || ''
  }

  private mounted(): void {
    this.toggleBodyClass('addClass', 'report')
  }

  private destroyed(): void {
    this.toggleBodyClass('removeClass', 'report')
  }
}
