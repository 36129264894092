/**
 * 現在のドリルに関する情報保持
 */
const state = {
  // 次へボタン押下可能か
  enableNext: false,
}

const getters = {
  enableNext: (state: any) => state.enableNext,
}

const mutations = {
  setEnableNext(state: any, payload: any) {
    state.enableNext = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
}
