import { Vue } from 'vue-property-decorator'

/**
 * キューの追加が必要な場合はこのstateに追加する
 */
const state = {
  // 現在ページの文字列をキューに追加
  currentPageQueue: [],
  // setTimeoutのIDをキューに追加
  setTimeoutIdQueue: [],
}

const getters = {}

const mutations = {
  enqueue(state: any, payload: { stateName: string; item: any }) {
    Vue.prototype.$logger.info(`-- Queue enqueue`)
    Vue.prototype.$logger.info(payload)

    if (!state[payload.stateName]) return

    state[payload.stateName].push(payload.item)
    Vue.prototype.$logger.info(state.currentPageQueue)
  },

  deleteAll(state: any, payload: string): number {
    Vue.prototype.$logger.info(`-- Queue deleteAll ${payload}`)
    Vue.prototype.$logger.info(state[payload])

    return state[payload].splice(0)
  },
}

const actions = {
  dequeue({ state }: any, payload: string): any {
    Vue.prototype.$logger.info(`-- Queue dequeue ${payload}`)

    if (!state[payload]) return

    return state[payload].shift()
  },

  size({ state }: any, payload: string): number {
    Vue.prototype.$logger.info(`-- Queue size ${payload}`)

    return state[payload].length
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
