





import { Vue, Component } from 'vue-property-decorator'

@Component
export default class StudentInnerframeLayout extends Vue {
  private toggleBodyClass(addRemoveClass: string, className: string) {
    const el = document.body

    if (addRemoveClass === 'addClass') {
      el.classList.add(className)
    } else {
      el.classList.remove(className)
    }
  }

  private mounted(): void {
    this.toggleBodyClass('addClass', 'student_innerframe')
  }

  private destroyed(): void {
    this.toggleBodyClass('removeClass', 'student_innerframe')
  }
}
