












import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class SelectWide extends Vue {
  @Prop()
  selectOptions!: []

  @Prop()
  value?: string

  @Prop({ default: null })
  onChangeFunction?: any

  @Prop({ default: 'var(--color-black)' })
  borderColor?: string

  get styleSet(): { [key: string]: string } {
    return {
      '--liner-color': this.borderColor!,
    }
  }

  private changeSelect(event: Event): void {
    this.$emit('input', (event.target as HTMLSelectElement).value)
    if (this.onChangeFunction !== null) this.onChangeFunction(event)
  }

  private clickOption(event: Event): void {
    if (this.onChangeFunction !== null) this.onChangeFunction(event)
  }
}
