






import { Component, Mixins, Vue } from 'vue-property-decorator'
import TeacherHeader from '@/components/organisms/TeacherHeader.vue'
import BootstrapLoader from '@/components/atoms/BootstrapLoader.vue'

@Component({
  components: {
    TeacherHeader,
  },
})
export default class TeacherLayout extends Mixins(BootstrapLoader) {
  private toggleBodyClass(addRemoveClass: string, className: string): void {
    const el = document.body

    if (addRemoveClass === 'addClass') {
      el.classList.add(className)
    } else {
      el.classList.remove(className)
    }
  }

  private mounted(): void {
    this.loadBootstrap()
    this.toggleBodyClass('addClass', 'teacher')
  }
  private destroyed(): void {
    this.unloadBootstrap()
    this.toggleBodyClass('removeClass', 'teacher')
  }
}
