






import { Component, Mixins, Vue } from 'vue-property-decorator'
import TeacherHeader from '@/components/organisms/TeacherHeader.vue'
import BootstrapLoader from '@/components/atoms/BootstrapLoader.vue'

@Component({
  components: {
    TeacherHeader,
  },
})
export default class TeacherSettingLayout extends Mixins(BootstrapLoader) {
  private toggleBodyClass(addRemoveClass: string, className: string) {
    const el = document.body

    if (addRemoveClass === 'addClass') {
      el.classList.add(className)
    } else {
      el.classList.remove(className)
    }
  }

  private mounted() {
    // 権限の無いユーザが設定画面にアクセスした場合、404
    const role = Vue.prototype.$cookies.get('authGdls').role as string
    const settableRoles = ['admin', 'academy', 'branch']
    if (!settableRoles.includes(role)) {
      this.$router.push('/error/error404')
    }

    this.loadBootstrap()
    this.toggleBodyClass('addClass', 'teacher')
  }

  private destroyed() {
    this.unloadBootstrap()
    this.toggleBodyClass('removeClass', 'teacher')
  }
}
