


































import { Component, Vue, Mixins, Watch, Ref } from 'vue-property-decorator'
import FillRound from '@/components/atoms/FillRound.vue'
import ImageRound from '@/components/atoms/ImageRound.vue'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import NowDateTime from '@/components/atoms/NowDateTime.vue'
import LessonStudent from '@/mixins/action-cable/LessonStudent/index'
import ModalNoteReport from '@/components/modules/drillsv3/organisms/ModalNoteReport.vue'
import { PAGE_TYPE } from '@/store/modules/DrillsV3'
import { PAGE_STUDY } from '@/constants'

@Component({
  components: {
    FillRound,
    ImageRound,
    ColoredBox,
    ButtonBase,
    ModalNoteReport,
  },
})
export default class StudentHeader extends Mixins(NowDateTime, LessonStudent) {
  private colorType = 'gray'
  @Ref() modalNoteReport!: ModalNoteReport

  private get showReport() {
    const currentPageName = this.$route.name || ''
    return PAGE_STUDY.includes(currentPageName) && this.isV3
  }

  // 生徒情報
  private get student(): { name: string; icon: string } {
    const cookie = Vue.prototype.$cookies.get('dataGdls')
    return {
      name: cookie?.userNickname || '',
      icon: cookie?.userIconUrl || '',
    }
  }
  // check if is V3, page type equal scoring and result
  private endConditionWithV3andScoringResultUnit() {
    return this.isV3 &&
      (this.endCondition['restriction'] === 'count' || this.endCondition['restriction'] === 'score') &&
      (this.pageTypeV3 === PAGE_TYPE.SCORING_UNIT ||
        this.pageTypeV3 === PAGE_TYPE.RESULT_UNIT ||
        this.pageTypeV2 === PAGE_TYPE.SCORING_UNIT ||
        this.pageTypeV2 === PAGE_TYPE.RESULT_UNIT)
      ? this.endCondition['left'] - 1
      : this.endCondition['left'] || 0
  }

  /**
   * 授業種別コード
   *
   * @return 授業種別コード
   */
  private get classCategoryCode(): string {
    return Array.isArray(this.$route.query.classCategoryCode)
      ? this.$route.query.classCategoryCode[0] || ''
      : this.$route.query.classCategoryCode || ''
  }

  private get showTimeLimit(): boolean {
    // 演習or宿題
    if (this.isV2) return false
    if (this.isV3 && (this.pageTypeV3 === PAGE_TYPE.RESULT_ALL || this.pageTypeV2 === PAGE_TYPE.RESULT_ALL))
      return false
    return Vue.prototype.$gdlsCookiesV3.isV3()
      ? this.endCondition['left'] > 0 && !this.$store.getters['drills/isClassCategoryCodeRK']
      : ['EN', 'SY'].includes(this.classCategoryCode) &&
          'restriction' in this.endCondition &&
          'left' in this.endCondition
  }

  private get pageTypeV3() {
    return this.$store.getters['drillsV3/pageType']
  }
  private get pageTypeV2() {
    return this.$store.getters['drillsV2/pageType']
  }
  // 終了条件
  private get endCondition(): { string: any } {
    return this.$store.getters['drills/endCondition']
  }

  // 赤吹き出しの数字の前のテキスト
  private get beforeText(): string {
    if (!('restriction' in this.endCondition)) return ''
    let text = ''
    switch (this.endCondition['restriction']) {
      case 'count':
      case 'time':
        text = 'あと'
        break
      case 'score':
        text = 'あと最大'
        break
    }
    return text
  }

  // 赤吹き出しの数字の後のテキスト
  private get afterText(): string {
    if (!('restriction' in this.endCondition)) return ''
    let text = ''
    switch (this.endCondition['restriction']) {
      case 'count':
      case 'score':
        text = '問'
        break
      case 'time':
        text = '分'
        break
    }
    return text
  }

  private callSupporterClicked() {
    if (this.colorType === 'gray') {
      this.colorType = 'darkgray'
    }
    this.callSupporter(true)
  }

  private async getExistSupporterCallHistory() {
    if (this.lessonId) {
      try {
        const res = await Vue.prototype.$http.httpWithToken.get(`/lessons/${this.lessonId}/supporterCallHistory`)
        this.colorType = res.data.existSupporterCallHistory ? 'darkgray' : 'gray'
      } catch (e) {
        console.error(`サポーターを呼ぶボタンの履歴取得でエラーが発生しました。 ${e}`)
        throw e
      }
    }
  }

  @Watch('lessonId')
  onLessonIdChanged() {
    this.getExistSupporterCallHistory()
  }

  private async mounted() {
    await this.getExistSupporterCallHistory()
  }

  private get isV2(): boolean {
    return Vue.prototype.$gdlsCookies.isV2()
  }
  private get isYGC(): boolean {
    return Vue.prototype.$gdlsCookiesV3.isYGC()
  }
  // Note Report
  private get isV3(): boolean {
    return Vue.prototype.$gdlsCookiesV3.isV3()
  }

  private callNoteReportClicked() {
    this.modalNoteReport.showModal()
  }
}
