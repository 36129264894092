















import { Component, Vue, Mixins, Ref, Watch } from 'vue-property-decorator'
import QuizHeader from '@/components/organisms/quiz/QuizHeader.vue'
import BaseQuiz from '@/mixins/quiz/BaseQuiz'
import ButtonBack from '@/components/molecules/quiz/ButtonBack.vue'
import UnitQuiz from '@/mixins/quiz/UnitQuiz'
import { AnswerType, CurriculumType } from '@/types/quiz/units'
import ModalShowText from '@/components/organisms/quiz/ModalShowText.vue'

@Component({
  components: {
    QuizHeader,
    ButtonBack,
    ModalShowText,
  },
})
export default class QuizLayout extends Mixins(Vue, BaseQuiz, UnitQuiz) {
  private toggleBodyClass(addRemoveClass: string, className: string): void {
    const el = document.body
    if (addRemoveClass === 'addClass') {
      el.classList.add(className)
    } else {
      el.classList.remove(className)
      el.setAttribute('style', '')
    }
  }

  private initCurriculum() {
    const pageKeepAnswer = ['/quiz/question', '/quiz/result', '/quiz/overview']
    if (!pageKeepAnswer.includes(this.$route.path)) {
      this.clearStorage()
    } else {
      const curriculum: CurriculumType = this.getCurriculum()
      const answers: AnswerType[] = this.getAnswerLocalStorage()
      const result = this.getResultLocalStorage()
      if (
        !curriculum || !curriculum.problems || ((!answers || answers.length < curriculum.problems.length) &&
          ['/quiz/result', '/quiz/overview'].includes(this.$route.path))
      ) {
        return this.$router.back()
      }
      if (curriculum) {
        this.$store.dispatch('question/setCurriculum', curriculum)
        this.$store.dispatch('question/setAnswers', answers)
        this.$store.dispatch('question/setResult', result)
      }
    }
  }

  @Watch('$route', { deep: true })
  watchRouter() {
    this.initCurriculum()
  }

  private created() {
    this.initCurriculum()
  }
  //check page login
  get showHeader() {
    const pathName = this.$route.fullPath
    const isPageLogin = pathName.includes('login') || pathName === '/'
    return !isPageLogin
  }

  private get hasPrePage() {
    return this?.$route?.query.hasOwnProperty('preTitle')
  }

  private get isPageQuestion() {
    return this?.$route?.path.includes('/quiz/question')
  }

  private mounted(): void {
    document.title = this.nameBook() || 'Quiz'
    this.toggleBodyClass('addClass', 'quiz')
  }

  private destroyed(): void {
    this.toggleBodyClass('removeClass', 'quiz')
  }

  private goBack() {
    this.$router.back()
  }
}
