import Vue from 'vue'
import { Route } from 'vue-router'

export type PathType = {
  dir: string
  subdir: string
  third: string
  fourth: string
}

/**
 * VueRouter::Routeの拡張ヘルパー
 * ディレクトリ抽出やリダイレクト判定などのメソッドを追加している
 */
export class RouterHelper {
  /**
   * Routeインスタンスを受け取り、ヘルパー内で参照する
   * @param route VueRouter::Routeインスタンス
   */
  constructor(public route: Route) {}
  /**
   * Routeからディレクトリをパースする
   * @return ディレクトリとサブディレクトリのを格納したオブジェクトを返却
   */
  public persePathes(): PathType {
    // パスから算出・先頭スラッシュ削除
    let path = this.route.path
    if (path.slice(0, 1) === '/') {
      path = path.replace('/', '')
    }
    const pathes: string[] = path.split('/')
    return {
      dir: pathes[0],
      subdir: pathes[1],
      third: pathes[2] || '',
      fourth: pathes[3] || '',
    }
  }

  /**
   * ナビゲーション可否判定
   * ログイン画面へのリダイレクト判定に使用する
   * @return true/ナビゲーション可能 false/ナゲーション不可(ログイン画面へリダイレクト)
   */
  public isNavigationable(): boolean {
    const dir = this.persePathes().dir
    const subdir = this.persePathes().subdir
    const third = this.persePathes().third
    dir !== 'error' && Vue.prototype.$quiz.setQuiz(dir === 'quiz')
    if (!dir || !subdir) return true
    if (
      subdir === 'login' ||
      subdir.includes('sign-in') ||
      // 専用ログインページ
      (subdir === 'sfl' && third === 'login') ||
      (subdir === 'method' && third === 'login') ||
      (dir === 'quiz' && (subdir === 'login' || !subdir || 'error')) ||
      ((subdir === 'g-method' || subdir === 'sozo') && third === 'login')
    )
      return true
    // cookie有効期限切れの際は各ログイン画面へリダイレクト
    const isV3 = Vue.prototype.$gdlsCookiesV3.isV3()
    if (subdir === 'v3' && !isV3) return false
    const isSozo = Vue.prototype.$gdlsCookiesV3.isSozo()
    if (subdir === 'sozo' && !isSozo) return false
    const cookies = Vue.prototype.$cookies
    if (cookies.isKey('authGdls') && cookies.isKey('dataGdls')) return true
    // SAML認証ページ
    if (
      subdir === 'gakken-saml-sso' ||
      subdir === 'gakken-saml-acs' ||
      (subdir === 'g-method' && third === 'gakken-saml-sso')
    )
      return true
    return false
  }

  /**
   * ディレクトリパスから、リダイレクト先のログイン画面を特定する
   * @return ログイン画面のルート名を返却する
   */
  public loginRouteName(): string {
    const dir = this.persePathes().dir
    // ログイン画面へリダイレクト
    let redirectPath = ''
    // TODO: G-IDの設定状態により、ログイン画面が異なるものは分岐する
    switch (dir) {
      case 'teacher':
        redirectPath = 'TeacherSignIn1'
        break
      case 'student':
        if (Vue.prototype.$gdlsCookiesV3.isPageSozo()) {
          redirectPath = 'StudentSozoLogin'
          break
        }
        if (Vue.prototype.$gdlsCookiesV3.isV3()) {
          redirectPath = 'StudentsV3Login'
          break
        }
        if (Vue.prototype.$gdlsCookies.isV2()) {
          redirectPath = 'StudentsYgcLogin'
        } else if (Vue.prototype.$gdlsCookies.isDemo()) {
          redirectPath = 'StudentsMethodLogin'
        } else {
          redirectPath = 'StudentsLogin'
        }
        break
      case 'quiz':
        redirectPath = 'QuizError'
        break
      case 'admin':
        redirectPath = 'AdminLogin'
        break
      case 'academy':
        if (Vue.prototype.$gdlsCookiesV3.isV3()) {
          redirectPath = 'AcademyLoginV3'
          break
        }
        redirectPath = 'AcademyLogin'
        break
    }
    return redirectPath
  }
}
