import _Vue from 'vue'

export default {
  install(Vue: typeof _Vue): void {

    const setQuiz = function (value: boolean): void {
      localStorage.setItem('quiz', JSON.stringify(value))
    }
    const isQuiz = function (): boolean {
      const quiz = localStorage.getItem('quiz')
      if (quiz) {
        return JSON.parse(quiz) as boolean;
      }
      return false;
    }

    Vue.prototype.$quiz = { setQuiz, isQuiz }
  },
}
