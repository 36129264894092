import { ElementHide, TypeDesign, ModifyText } from '@/types/student/design'
import { Component, Vue } from 'vue-property-decorator'
@Component
export default class PropertiesDesign extends Vue {
  protected icon: any = {
    iconCorrecting: '',
    iconExam: '',
    iconHomework: '',
    iconMeasure: '',
    getIconModeCustom: null,
    iconPlan: '',
    iconRevise: '',
    iconSchedule: '',
    iconTimeline: '',
    iconChallenge: ''
  }

  protected characters: {
    default: string
    hello: string
    blink: string
    hello_blink: string
    sad: string
    error: string
    correct: string
    excellent: string
  } = { default: '', hello: '', blink: '', hello_blink: '', sad: '', error: '', correct: '', excellent: '' }

  protected hide: ElementHide = {}

  protected colorButton = {
    primary: '',
    second: '',
    active: '',
    colorText: '',
    activeSecond: '',
  }

  protected logo = ''

  protected backgroundColor = ''

  protected onlyShow: any = {
    dashboard: '',
  }

  protected modifyText: ModifyText = {}

  private mapLink = {
    isBtnHomework: '/student/v3/homework/SY',
    isBtnSetting: '/student/setting',
    isBtnSchedule: '/student/v3/schedule',
    isBtnTimeline: '/student/v3/timeline',
  }

  private checkPermission() {
    const pathName = this.$route.path
    return !Object.keys(this.hide).some((key) => {
      if (this.hide[key] && this.mapLink?.[key] === pathName) return true
      return false
    })
  }

  private created() {
    const properties: TypeDesign = Vue.prototype.$design.properties()
    const { iconSidebar, getIconModeCustom, hide, onlyShow, logo, backgroundColor, modifyText } = properties
    this.logo = logo
    this.backgroundColor = backgroundColor
    this.modifyText = { ...modifyText }
    this.icon = { ...iconSidebar, getIconModeCustom }
    this.hide = { ...hide }
    this.colorButton = {
      primary: properties.buttonSidebarPrimary,
      second: properties.buttonSidebarSecond,
      active: properties.buttonColorActiveSidebar,
      colorText: properties.colorTextSidebar,
      activeSecond: properties.buttonColorActiveSidebarSecondary,
    }
    this.characters = properties.characters
    this.onlyShow = { ...onlyShow }
    if (!this.checkPermission()) {
      return this.$router.push('/student/v3/dashboard')
    }
  }
}
