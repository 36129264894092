







import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class HeaderModal extends Vue {
  @Prop({default: ''})
  private title?: string

  private close(){
    this.$emit('close')
  }
}
