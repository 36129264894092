const state = {
  listBadge: []
}

const getters = {
  listBadge: (state: any) => state.listBadge
}

const mutations = {
  setListBadge(state: any, payload: any) {
    state.listBadge = [...payload]
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
}
