

















import { Component, Mixins, Vue } from 'vue-property-decorator'
import FillRound from '@/components/atoms/FillRound.vue'
import ImageRound from '@/components/atoms/ImageRound.vue'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import DropdownMenu from '@/components/molecules/DropdownMenu.vue'
import NowDateTime from '@/components/atoms/NowDateTime.vue'
import LoginMethods from '@/components/organisms/LoginMethods.vue'
import LogoutMenu from '@/components/organisms/LogoutMenu.vue'

@Component({
  components: {
    FillRound,
    ImageRound,
    ColoredBox,
    ButtonBase,
    DropdownMenu,
    LogoutMenu,
  },
})
export default class CustomerHeader extends Mixins(NowDateTime, LoginMethods) {
  private customer = { name: '' }
  private isV3 = Vue.prototype.$gdlsCookiesV3.isV3()
  private dropdownMenuDatas: object[] = [
    { url: '/academy/branch', name: '教室管理' },
    { url: this.isV3 ? '/academy/v3/request-edit' : '/academy/request-edit', name: '申請' },
    { url: '/academy/request', name: '申請管理' },
    { url: '/academy/request-default', name: '塾デフォルト' },
    { url: '/academy/csv-download', name: '帳票出力' },
  ]

  private mounted() {
    // ログアウト用のモードを設定
    this.mode = this.MODE.ACADEMY
    this.setAcademyName()
  }

  /**
   *  塾名取得
   */
  private async setAcademyName() {
    const academyId = Vue.prototype.$cookies.get('dataGdls')?.academyId
    if (!academyId) return

    await Vue.prototype.$http.httpWithToken.get(`/academies/${academyId}`).then((res: any) => {
      this.customer.name = res.data.name
    })
  }
}
