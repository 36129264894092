import _Vue from 'vue'

export default {
  install(Vue: typeof _Vue): void {
    const containerId = 'quiz-container-count-down'
    const complete = function (): void {
      const container = document.getElementById(containerId)
      if (container) {
        container.remove()
      }
    }
    const start = function (time = 3) {
      const container = document.createElement('div')
      container.id = containerId
      const shape = document.createElement('div')
      shape.id = 'container'
      container.appendChild(shape)
      document.body.appendChild(container)

      let countDown = time
      shape.innerText = countDown+''

      const timerId = setInterval(()=> {
        countDown--
        if(countDown === 0) {
          complete()
          clearInterval(timerId)
        }
        shape.innerText = countDown+''
      }, 1000)
    }

    Vue.prototype.$count = { start, complete }
  },
}
