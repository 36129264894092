import _Vue from 'vue'

export default {
  install(Vue: typeof _Vue): void {
    /**
     * gdlsV2クッキーにisV2値をセット
     *
     * @param isV2 V2であるかの真偽値
     */
    const setV2 = function (isV2: boolean): void {
      Vue.prototype.$cookies.set('gdlsV2', { isV2: isV2, isDemo: false })
    }
    /**
     * gdlsV2クッキーにisDemo値をセット
     *
     * @param isDemo デモであるかの真偽値
     */
    const setDemo = function (isDemo: boolean): void {
      Vue.prototype.$cookies.set('gdlsV2', { isV2: false, isDemo: isDemo })
    }
    /**
     * gdlsV2クッキーからisV2値を取得
     *
     * @returns V2であるかの真偽値（クッキーがなければfalse）
     */
    const isV2 = function (): boolean {
      const cookie = Vue.prototype.$cookies.get('gdlsV2')
      if (!cookie) return false

      return cookie.isV2
    }
    /**
     * gdlsV2クッキーからisDemo値を取得
     *
     * @returns デモであるかの真偽値（クッキーがなければfalse）
     */
    const isDemo = function (): boolean {
      const cookie = Vue.prototype.$cookies.get('gdlsV2')
      if (!cookie) return false

      return cookie.isDemo
    }
    /**
     * gdlsV2クッキーからisV2/isDemo値を取得
     *
     * @returns V2またはデモであるかの真偽値（クッキーがなければfalse）
     */
    const isV2OrDemo = function (): boolean {
      const cookie = Vue.prototype.$cookies.get('gdlsV2')
      if (!cookie) return false

      return cookie.isV2 || cookie.isDemo
    }

    Vue.prototype.$gdlsCookies = { setV2, isV2, setDemo, isDemo, isV2OrDemo }
  },
}
