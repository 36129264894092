
import { Component, Vue } from 'vue-property-decorator'

// mixinして使用する
// コンポーネントの単一ルートにModalBaseが指定されている場合のみ使用可能

interface Modal {
  show(): void
  hide(): void
}
@Component
export default class ModalBaseMethod extends Vue {
  public show(): void {
    ;((this.$children[0] as unknown) as Modal).show()
  }

  public hide(): void {
    ;((this.$children[0] as unknown) as Modal).hide()
  }
}
