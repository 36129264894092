import { Vue } from 'vue-property-decorator'

/**
 * スタックの追加が必要な場合はこのstateに追加する
 */
const state = {
  // 現在ページの文字列をスタックに追加
  currentPageStack: [],
}

const getters = {}

const mutations = {
  push(state: any, payload: { stateName: string; item: any }) {
    Vue.prototype.$logger.info(`-- Stack push`)
    Vue.prototype.$logger.info(payload)

    if (!state[payload.stateName]) return

    state[payload.stateName].push(payload.item)
    Vue.prototype.$logger.info(state.currentPageStack)
  },

  deleteAll(state: any, payload: string): number {
    Vue.prototype.$logger.info(`-- Stack deleteAll ${payload}`)
    Vue.prototype.$logger.info(state[payload])

    return state[payload].splice(0)
  },
}

const actions = {
  pop({ state }: any, payload: string): any {
    Vue.prototype.$logger.info(`-- Stack pop ${payload}`)

    if (!state[payload]) return

    return state[payload].pop()
  },

  size({ state }: any, payload: string): number {
    Vue.prototype.$logger.info(`-- Stack size ${payload}`)

    return state[payload].length
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
