








import { Component, Vue } from 'vue-property-decorator'
import StudentHeader from '@/components/organisms/StudentHeader.vue'

@Component({
  components: {
    StudentHeader,
  },
})
export default class StudentHeadmenuLayout extends Vue {
  private toggleBodyClass(addRemoveClass: string, className: string): void {
    const el = document.body

    if (addRemoveClass === 'addClass') {
      el.classList.add(className)
    } else {
      el.classList.remove(className)
    }
  }

  private mounted(): void {
    this.toggleBodyClass('addClass', 'student')
  }

  private destroyed(): void {
    this.toggleBodyClass('removeClass', 'student')
  }

  private get isV2OrDemo(): boolean {
    return Vue.prototype.$gdlsCookies.isV2OrDemo()
  }
}
