export enum DesignName {
  Default = 'default',
  Custom = 'custom',
  OnAir = 'onair',
  YGC = 'ygc',
}
export interface TypeDesign {
  // font size of app
  fontSize: number
  // color of app
  color: string
  // color background of app
  backgroundColor: string
  // logo of app
  logo: string
  // favicon of app
  favicon: string
  // icon sidebar
  iconSidebar: IconSidebar
  // color of buttons above in sidebar
  buttonSidebarPrimary: string
  // color of 2 buttons bottom  in sidebar
  buttonSidebarSecond: string
  // color active when click button in sidebar
  buttonColorActiveSidebar: string
  // color active when click button in sidebar
  buttonColorActiveSidebarSecondary: string
  // color active when click button in sidebar
  colorTextSidebar: string
  /* color gradient from right to left  of button 学習を始める in dasboard
  if value of start same end then color not gradient */
  colorBtnStartLearning: { start: string; end: string; color: string }
  /* color gradient from right to left of button 授業の振替え in dasboard
  if value of start same end then color not gradient */
  colorBtnChangeClass: { start: string; end: string; color: string }
  // get icon mode custom
  getIconModeCustom: (name: string) => string
  // get hide
  hide: ElementHide
  onlyShow: {
    dashboard?: string
  }
  modifyText: ModifyText
  characters: Characters
  //define new key ...
}

export interface Characters {
  default: string
  hello: string
  blink: string
  hello_blink: string
  sad: string
  error: string
  correct: string
  excellent: string
}

export interface ElementHide {
  isBtnSetting?: boolean
  isModeDefault?: boolean
  isModeCustom?: boolean
  isBtnHomework?: boolean
  isBtnExam?: boolean
  isTextSmallOfExam?: boolean
  isBtnCorrecting?: boolean
  isBtnMeasure?: boolean
  isBtnPlan?: boolean
  isBtnSchedule?: boolean
  isBtnRevise?: boolean
  isBtnTimeline?: boolean
  //define key more ...
}

export interface ModifyText {
  isModeDefault?: string
  isBtnHomework?: string
  isBtnExam?: string
  isTextSmallOfExam?: string
  isBtnCorrecting?: string
  isBtnMeasure?: string
  isBtnPlan?: string
  isBtnSchedule?: string
  isBtnRevise?: string
  isBtnTimeline?: string
  isBtnChallenge?: string
  //define key more ...
}

export interface IconSidebar {
  // icon of button 間違い直し in sidebar
  iconCorrecting: string
  // icon of button ドリル&テスト in sidebar
  iconExam: string
  // icon of button 宿題 in sidebar
  iconHomework: string
  // icon of button 入試対策 in sidebar
  iconMeasure: string
  // icon of button Modes in sidebar
  iconMode: string
  // icon of button 学習計画 in sidebar
  iconPlan: string
  // icon of button 学習のふり返り in sidebar
  iconRevise: string
  // icon of button 予定表 in sidebar
  iconSchedule: string
  // icon of button タイムライン in sidebar
  iconTimeline: string

  iconChallenge: string
}

export interface TypeAssest {
  logo?: string
  favicon?: string
  iconCorrecting?: string
  iconExam?: string
  iconHomework?: string
  iconMeasure?: string
  iconMode?: string
  iconPlan?: string
  iconRevise?: string
  pencil?: string
  board?: string
  paper?: string
  hat?: string
  flower?: string
  chart?: string
  book?: string
  homeschooling?: string
  'hand-pen'?: string
  watermelon?: string
  growth?: string
  calendar?: string
  q?: string
  snowman?: string
  'maple-leaf'?: string
}

export interface DataConfig {
  fontSize?: string
  color?: string
  backgroundColor?: string
  sidebar?: {
    colorPrimary?: string
    colorSecondary?: string
    colorActive?: string
    colorActiveSecondary?: string
    colorText?: string
  }
  colorBtnStartLearning?: {
    start: string
    end: string
    color: string
  }
  colorBtnChangeClass?: {
    start: string
    end: string
    color: string
  }
  modify: {
    iconMode?: {
      isAll?: boolean
    }
    iconSidebar?: {
      isAll?: boolean
    }
  }
  hide?: {
    isModeCustom?: boolean
    isBtnHomework?: boolean
    isBtnSetting?: boolean
    isTextSmallOfExam?: boolean
    isBtnTimeline?: boolean
  }
  onlyShow?: {
    dashboard?: string
  }
  modifyText?: {
    isModeDefault?: string
  }
  type?: {
    logo?: string
    favicon?: string
  }
  hasCustomCharacter?: boolean
}
