import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { LANG_KEY } from '@/constants'
const jaMessage = require('@/i18n/language/ja.json')
const enMessage = require('@/i18n/language/en.json')
/*
  NOTE: language codes follow ISO 639-1 https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes 
*/

export enum Language {
  JAPANESE = 'ja',
  ENGLISH = 'en',
}

export const DEFAULT_LANGUAGE = Language.JAPANESE

const messages = {
  [Language.JAPANESE]: jaMessage,
  [Language.ENGLISH]: enMessage,
}

export const getCookie = (key: string) => {
  const name = key + '='
  const decodedCookie = decodeURIComponent(document.cookie)
  const listCookies = decodedCookie.split(';').map((item) => item.trim())
  const cookieTarget = listCookies.find((item) => item.includes(name)) || ''
  if (cookieTarget) {
    const value = cookieTarget?.substring(name.length)
    if (value.includes('{')) return JSON.parse(value)
    return value
  }
  return ''
}

const parseCodeLanguage = (lang: string) => {
  const pathName = location.pathname
  const isV3 = getCookie('gdlsV3')?.isV3
  if (!((pathName.includes('/student/') && isV3) || pathName === '/student/v3/login')) return DEFAULT_LANGUAGE
  if (lang.toLowerCase().includes(Language.ENGLISH)) return Language.ENGLISH
  if (lang.toLowerCase().includes(Language.JAPANESE)) return Language.JAPANESE
  return DEFAULT_LANGUAGE
}

const getLanguage = () => {
  const language = localStorage.getItem(LANG_KEY) || DEFAULT_LANGUAGE
  return parseCodeLanguage(language)
}

export const setLanguage = (lang: string) => {
  try {
    localStorage.setItem(LANG_KEY, lang)
  } catch (error) {
    alert('Not change language, please reload page and try again!')
  }
}

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: getLanguage(),
  messages,
  fallbackLocale: DEFAULT_LANGUAGE,
})

export default i18n