






import { Component, Vue } from 'vue-property-decorator'
import TeacherHistoryBottomPanel from '@/components/organisms/TeacherHistoryBottomPanel.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'

@Component({
  components: {
    TeacherHistoryBottomPanel,
    ButtonBase,
  },
})
export default class ButtomPanelReport extends Vue {
  private linkReportBug = 'https://pf.gakken.jp/user/op_enquete.gsp?sid=GDL&mid=006809WZ&hid=irxtGt43s_0'
  private get isShowReportBug() {
    const role = Vue.prototype.$cookies.get('authGdls').webRole
    const isBug = this.$route.query.isBug == 'true'
    return ['teacher', 'branch', 'academy'].includes(role) && isBug
  }
  private async goBackPrePage() {
    const name = Array.isArray(this.$route.query.prevPage)
      ? this.$route.query.prevPage[0] || ''
      : this.$route.query.prevPage || ''
    const id = Array.isArray(this.$route.query.prevId)
      ? this.$route.query.prevId[0] || ''
      : this.$route.query.prevId || ''
    const learningType = Array.isArray(this.$route.query.prevType)
      ? this.$route.query.prevType[0] || ''
      : this.$route.query.prevType || ''
    const prevPage = Array.isArray(this.$route.query.beforePage)
      ? this.$route.query.beforePage[0] || ''
      : this.$route.query.beforePage || ''

    const targetQueryKeys = ['selectedSubject', 'grade', 'subject', 'unit', 'targetRadio', 'targetSelect']
    const targetQuery = {}
    for (const [key, value] of Object.entries(this.$route.query)) {
      if (targetQueryKeys.includes(key)) targetQuery[key] = value
    }
    if (prevPage) targetQuery['prevPage'] = prevPage

    const route = { name: name }
    if (id && learningType) {
      route['params'] = { id: id, learningType: learningType }
    }
    if (Object.keys(targetQuery).length > 0) route['query'] = targetQuery

    this.$router.push(route)
  }

  private redirectPageReportBug() {
    const link = document.createElement('a')
    link.setAttribute('href', this.linkReportBug)
    link.setAttribute('target', '_blank')
    link.click()
    link.remove()
  }
}
