import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import tippy, { Placement } from 'tippy.js'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light-border.css'

@Component
export default class TooltipQuiz extends Vue {
  protected tooltipId = "id-"+this.$uuid.v4()
  @Prop({ default: '' })
  content!: string

  @Prop({ default: 'bottom' })
  placement!: Placement

  @Prop({ default: 'click' })
  trigger!: 'mouseenter focus' | 'click' | 'focusin' | 'mouseenter click' | 'manual'

  protected tippy?: any

  protected title = ''


  protected mounted() {
    this.initTippy()
  }

  protected initTippy() {
    // 外部ライブラリのtippyを使用
    // その他必要に応じてオプションを追加する場合は公式を参照(https://atomiks.github.io/tippyjs/)
    this.tippy?.[0] && this.tippy[0].destroy();
    this.tippy = tippy(`#${this.tooltipId}`, {
      content: this.content || this.title,
      placement: this.placement,
      trigger: this.trigger,
      theme: 'light-border',
      maxWidth: 'none',
    })
  }

  /**
   * contentに変更があった場合、再度初期化を行う
   */
  @Watch('content')
  onChangeContent() {
    this.initTippy()
  }
}
