










import { Component, Mixins, Prop } from 'vue-property-decorator'
import HeaderModal from '@/components/atoms/quiz/HeaderModal.vue'
import ModalBase from '@/components/molecules/ModalBase.vue'
import ModalBaseMethod from '@/components/molecules/ModalBaseMethod.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import BodyModal from '@/components/atoms/quiz/BodyModal.vue'

@Component({
  components: {
    HeaderModal,
    BodyModal,
    ModalBase,
    ButtonBase,
  },
})
export default class ModalLeaveQuestion extends Mixins(ModalBaseMethod) {
  @Prop({ default: null })
  onLeave?: () => void

  private leave() {
    this.hide()
    if (this.onLeave) {
      return this.onLeave()
    }
    this.$router.back()
  }
}
