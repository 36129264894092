






import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  inheritAttrs: false,
})
export default class ButtonBase extends Vue {
  @Prop()
  size?: string

  @Prop()
  colortype?: string

  get classObject(): { [key: string]: boolean } {
    const { colortype, size } = this

    return {
      'button-base__size-default': !size,
      'button-base__size-small': size === 'small',
      'button-base__size-smlim': size === 'smlim',
      'button-base__size-slim': size === 'slim',
      'button-base__size-xslim': size === 'xslim',
      'button-base__size-middle': size === 'middle',
      'button-base__size-large': size === 'large',
      'button-base__size-block': size === 'block',
      'button-base__size-student': size === 'student',
      'button-base__size-xlarge': size === 'xlarge',
      'button-base__color-default': !colortype,
      'button-base__color-blue': colortype === 'blue',
      'button-base__color-white': colortype === 'white',
      'button-base__color-gray': colortype === 'gray',
      'button-base__color-darkgray': colortype === 'darkgray',
      'button-base__color-disabled': colortype === 'disabled',
      'button-base__color-gradation-orange': colortype === 'gradation-orange',
      'button-base__color-white-no-border': colortype === 'white-no-border',
      'button-base__color-skyblue': colortype === 'skyblue',
      'button-base__color-lightblue': colortype === 'lightblue',
      'button-base__color-lineblue': colortype === 'lineblue',
      'button-base__color-pink': colortype === 'pink',
      'button-base__color-red': colortype === 'red',
      'button-base__color-green': colortype === 'green',
      'button-base__color-orange': colortype === 'orange',
      'button-base__color-pointer-events-none': colortype === 'pointer-events-none',
      'button-base__color-pointer-events-none-2': colortype === 'pointer-events-none-2',
      'button-base__color-brown': colortype === 'brown',
      'button-base__color-light_orange': colortype === 'light_orange',
    }
  }

  private clickEvent(key: Event): void {
    this.$emit('click-event', key)
  }
}
